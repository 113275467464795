/* eslint-disable camelcase */
import { FC, ReactNode } from 'react'
import { useAuth } from '../../hooks/auth'

export type SpiOptions =
  | 'SERVICES'
  | 'CUSTOMER_TAGS'
  | 'USERS'
  | 'CUSTOMER_ORIGENS'
  | 'INSURANCES'
  | 'BILLS_TO_PAY'
  | 'BILLS_TO_RECEIVE'
  | 'PRESCRIPTIONS'
  | 'SERVICE_GROUPS'
  | 'WAYS_TO_RECEIVE'
  | 'SERVICES_DOCUMENTS'
  | 'WAYS_TO_PAYMENTS'
  | 'EXAMS'
  | 'PRODUCTS'
  | 'ATTENDANCES_SPOTS'
  | 'ENTITIES'
  | 'FORMS'
  | 'COST_CENTERS'
  | 'PREFERENCES'
  | 'ABOUT_CLINIC'
  | 'SCHEDULE_CORRECTION'
  | 'CONCILIATION'
  | 'BALANCE'
  | 'PRODUCTS'
  | 'TRANSACTION_HISTORY'
  | 'FISCAL_CONTROL'
  | 'PRINT_TEMPLATES'
  | 'TRANSFER_REPORTS'
  | 'PRODUCT_GROUPS'
  | 'ENTRIES'
  | 'OUTFLOWS'
  | 'CASH_FLOW'
  | 'RESULT'
  | 'FISCAL_UNITS'
  | 'DELETE_CUSTOMER_PAYMENT'
  | 'NOTES'

type PermissionComponentProps = {
  children: ReactNode
  spiCode?: SpiOptions | undefined
  ipCode?: 'VIEW' | 'CREATE' | 'EDIT' | 'DELETE' | 'APPROVE' | 'INACTIVATE'
  group?: Array<SpiOptions>
  ignore?: boolean
}

export const PermissionComponent: FC<PermissionComponentProps> = ({
  children,
  ipCode,
  spiCode,
  group,
  ignore
}) => {
  const { decodeToken, permissions } = useAuth()

  let isPermission = false
  let arrayGroup: Array<string> = []

  // item Component
  if (decodeToken?.is_superuser) {
    isPermission = true
  }

  if (ignore === true) {
    isPermission = true
  }

  permissions?.forEach((item) => {
    arrayGroup = [...arrayGroup, item.system_permission_item.code]
    if (item?.system_permission_item?.code === spiCode) {
      if (item?.item_permission?.code === ipCode) {
        isPermission = true
      }
    }
  })

  group !== undefined &&
    arrayGroup?.forEach((codename) => {
      group?.find((code) => {
        if (code === codename) {
          isPermission = true
        }

        return null
      })
    })

  return <>{isPermission && children}</>
}
