/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../services/apiAuth'

type User = {
  id: number
  name: string
}

export type Data = {
  id?: number
  name: string | null
  is_active: boolean
  event_date: string
  type: number
  message: string | null
  specialists?: {
    id: number
  }
  users?: {
    id: number
  }
  equipments?: {
    id: number
  }
}

export type Props = {
  init: boolean
}

interface Specialist {
  id: number
  user: {
    id: number
    name: string
  }
  social_name: string
}

interface Clients {
  id: number
  name: string
}

interface Users {
  id: number
  user: {
    id: number
    name: string
  }
  social_name: string
}

interface Equipments {
  id: number
  name: string
}

type SpecialistData = Array<Specialist>

type UsersData = Array<Users>

type EquipmentsData = Array<Equipments>

type GetClientsData = {
  results: Array<Clients>
}

type GetResponse = {
  results: SpecialistData
}

type GetUsersResponse = {
  results: UsersData
}

type GetEquipmentsResponse = {
  results: EquipmentsData
}

const BASE_URL = 'clinics/notes/'

export const useAttendance = () => {
  // terceiros
  const router = useHistory()
  const toast = useToast()
  // states
  const [specialists, setSpecialists] = useState<SpecialistData>([])
  const [users, setUsers] = useState<UsersData>([])
  const [equipments, setEquipments] = useState<EquipmentsData>([])

  useEffect(() => {
    apiAuth
      .get<GetResponse>(`users/`, {
        params: {
          is_active: true,
          is_specialist: true
        }
      })
      .then((response) => {
        setSpecialists(response.data.results)
      })

    apiAuth
      .get(`users/`, {
        params: {
          is_active: true,
          is_specialist: false
        }
      })
      .then((response) => {
        setUsers(response.data.results)
      })

    apiAuth
      .get<GetEquipmentsResponse>(`clinics/equipments/`, {
        params: {
          is_active: true
        }
      })
      .then((response) => {
        setEquipments(response.data.results)
      })

    return () => {}
  }, [])

  const getClients = async (search?: string) => {
    if (search !== undefined && search?.length <= 2) {
      return []
    }

    try {
      const { data } = await apiAuth.get(`clinics/patients/`, {
        params: {
          is_active: true,
          name: search ?? '',
          noPaginate: 1
        }
      })
      return data.results.map((client: any) => {
        return {
          value: client.id,
          label: client.name
        }
      })
    } catch (err: any) {
      return []
    }
  }

  // função para criar
  async function createNote(payload: Data, callback?: string) {
    try {
      const { data } = await apiAuth.post(BASE_URL, payload)

      if (data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push(callback ?? '/notes')
        }, 2000)
      }
    } catch (error: any) {
      toast({
        title: 'Error!',
        description: `${error.response?.data?.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  // função para editar
  async function updateNote(payload: Data) {
    try {
      const res = await apiAuth.put(`${BASE_URL}${payload.id}/`, payload)

      if (res.data.id !== undefined) {
        toast({
          title: 'Atualização realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }

      setTimeout(() => {
        router.push('/notes')
      }, 2000)
    } catch (error: any) {
      toast({
        title: 'Error!',
        description: `${error.response?.data?.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  // export
  return {
    createNote,
    updateNote,
    specialists,
    getClients,
    equipments,
    users
  }
}
