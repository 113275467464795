import { parseISO, isAfter, startOfDay } from 'date-fns'

export const expireDateVerify = (eventDate: string) => {
  const currentDate = startOfDay(new Date())

  const parsedEventDate = startOfDay(parseISO(eventDate))

  const eventHasPassed = isAfter(currentDate, parsedEventDate)

  if (eventHasPassed) {
    return 'red.500' // Cor para evento expirado
  } else {
    return 'green.500' // Cor para evento não expirado
  }
}
