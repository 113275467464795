/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-destructuring */
import React, { useCallback, useEffect, useState } from 'react'
import { addMonths, format } from 'date-fns'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { useAuth } from '../../hooks/auth'
import { mask } from '../../utils/mask'

type LaunchData = {
  competence: string
  amountPortion: string
  portion: number
  dueDate: string
}

export type SuppliersValue = {
  label: string
  value: string
}

const maskCurrencyBR = [
  '9,99',
  '99,99',
  '999,99',
  '9.999,99',
  '99.999,99',
  '999.999,99',
  '9.999.999,99'
]

const maskDateCompetence = ['99/9999']
const maskDateDueDate = ['99/99/9999']

const maskDolar = [
  '9.99',
  '99.99',
  '999.99',
  '9,999.99',
  '99,999.99',
  '999,999.99',
  '9,999,999.99'
]

export const useLogicBillsToPay = () => {
  const { decodeToken } = useAuth()

  const [launch, setLaunch] = useState<LaunchData[]>([
    {
      competence: format(new Date(), 'MM/yyyy'),
      amountPortion: '',
      portion: 1,
      dueDate: format(new Date(), 'yyyy-MM-dd')
    }
  ])
  const [isConfirmed, setIsConfirmed] = useState(true)
  const [masker, setMasker] = useState<string | string[]>('')
  const [nominalPrice, setNominalPrice] = useState('')
  const [supplier_posting, setSupplierPosting] = useState<SuppliersValue>({
    value: '',
    label: ''
  })
  const [category, setCategory] = useState<SuppliersValue>({
    value: '',
    label: ''
  })
  const [cost_center, setCostCenter] = useState<SuppliersValue>({
    value: '',
    label: ''
  })
  const [competenceDateState, setCompetenceDateState] = useState('')
  const [dueDateState, setNewDueDateState] = useState('')
  const [name, setName] = useState('')

  // functions add New Launch
  function handleAddFieldLaunchDateIndex() {
    const values: LaunchData[] = [...launch]
    const lengthAux = values.length - 1

    // Reorder Launch
    const valuesReordered = values.map((value, index) => {
      return {
        competence: value.competence,
        amountPortion: value.amountPortion,
        portion: index + 1,
        dueDate: value.dueDate
      }
    })

    const competence = values[lengthAux].competence
    const dueDate = values[lengthAux].dueDate

    const newCompetenceString = remaskCaractersAll(competence)
    const newDueDateString = remaskCaractersAll(dueDate)

    // logic Get new Date + 1 month competence
    const vc = newCompetenceString.split('')
    const dayVc = Number(`${0}${5}`)
    const monthVc = Number(`${vc[0]}${vc[1]}`) - 1
    const yearVc = Number(`${vc[2]}${vc[3]}${vc[4]}${vc[5]}`)
    const dateVc = new Date(yearVc, monthVc, dayVc)
    const nextMonthVc = addMonths(dateVc, 1)
    const FormatMonthVc = String(format(nextMonthVc, 'MM/yyyy'))

    // logic Get new Date + 1 month dueDate
    const vd = newDueDateString.split('')
    const dayVd = Number(`${vd[6]}${vd[7]}`)

    const monthVd = Number(`${vd[4]}${vd[5]}`) - 1
    const yearVd = Number(`${vd[0]}${vd[1]}${vd[2]}${vd[3]}`)
    const dateVd = Date.parse(new Date(yearVd, monthVd, dayVd).toISOString())
    const nextMonthVd = addMonths(dateVd, 1)
    const FormatMonthVd = String(format(nextMonthVd, 'yyyy-MM-dd'))

    // Get value last index
    setLaunch([
      ...launch,
      {
        competence: FormatMonthVc,
        amountPortion: valuesReordered[lengthAux].amountPortion,
        portion: valuesReordered[lengthAux].portion + 1,
        dueDate: FormatMonthVd
      }
    ])
  }
  // functions selectComponent Get value
  function handleSelectSuppliers(event: SuppliersValue) {
    setSupplierPosting(event)
  }

  function onRemoveFieldLaunchDateIndex(index: number) {
    const values = [...launch]
    values.splice(index, 1)

    // reordering the number of launch
    const valuesNewNumber = values.map((value, i) => {
      return {
        competence: value.competence,
        amountPortion: value.amountPortion,
        portion: i + 1,
        dueDate: value.dueDate
      }
    })

    setLaunch(valuesNewNumber)
  }

  function handleInputChangeAmountLaunch(
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) {
    const values: LaunchData[] = [...launch]
    const valuesAux = values.slice(index, index + 1)
    const newValue = valuesAux.map((value) => {
      return {
        competence: value.competence,
        amountPortion: event.currentTarget.value,
        portion: value.portion,
        dueDate: value.dueDate
      }
    })

    const valuesChange = values
    valuesChange[index] = newValue[0]

    setLaunch(valuesChange)
  }

  function handleInputChange(
    event: React.FormEvent<HTMLInputElement>,
    index: number,
    maskUsage?: string
  ) {
    const values: any = [...launch]
    if (maskUsage === 'MM/AAAA') {
      values[index][event.currentTarget.name] = mask(
        event.currentTarget.value,
        '99/9999'
      )
    } else {
      values[index][event.currentTarget.name] = event.currentTarget.value
    }

    setLaunch(values)
  }

  const changeCompetenceDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value

      setCompetenceDateState(mask(value, maskDateCompetence))
    },
    [maskDateCompetence]
  )

  const changeDueDate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewDueDateState(e.target.value)
    },
    []
  )

  function maskerCurrency() {
    if (decodeToken.clinic_country === 'United States of America') {
      setMasker(maskDolar)
    } else {
      setMasker(maskCurrencyBR)
    }
  }

  useEffect(() => {
    if (decodeToken !== undefined) {
      maskerCurrency()
    }
  }, [decodeToken])

  return {
    launch,
    handleAddFieldLaunchDateIndex,
    handleInputChangeAmountLaunch,
    handleInputChange,
    onRemoveFieldLaunchDateIndex,
    changeCompetenceDate,
    setNominalPrice,
    changeDueDate,
    setCompetenceDateState,
    competenceDateState,
    dueDateState,
    setNewDueDateState,
    handleSelectSuppliers,
    setLaunch,
    setName,
    name,
    nominalPrice,
    supplier_posting,
    setSupplierPosting,
    maskDolar,
    maskDateCompetence,
    maskDateDueDate,
    maskCurrencyBR,
    category,
    setCategory,
    cost_center,
    setCostCenter,
    setIsConfirmed,
    isConfirmed
  }
}
