/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
import {
  Box,
  Button,
  Flex,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import ls from 'localstorage-slim'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { StateOptions } from '../../../utils/json/states'

import { Header } from '../../../components/Header'
import { Sidebar } from '../../../components/Sidebar'

import { Input } from '../../../components/Form/Input'
import { SelectComponent } from '../../../components/Form/SelectComponent'
import { CardContainer } from '../../../components/molecules/CardContainer'

import { InputPhone2 } from '../../../components/Form/InputPhone2'
import { PermissionComponent } from '../../../components/Permissions'
import { expireDateNowAdd8hours, useAuth } from '../../../hooks/auth'
import { apiAuth } from '../../../services/apiAuth'
import Countries from '../../../utils/json/countries.json'
import Currencies from '../../../utils/json/currencies.json'
import Fusos from '../../../utils/json/fusos.json'
import { mask } from '../../../utils/mask'
import { useAddress } from '../../../utils/services/useAddress'

type AboutClinicForm = {
  name: string
  responsible: string
  doc: string
  lingua: string
  moeda: string
  fuso: string
  pais: string
  cep: string
  rua: string
  numero: string
  bairro: string
  complemento: string
  estado: string
  cidade: string
  telefone: string
}

interface DadosClinicaData {
  name: string
  language: string
  timezone: string
  responsible: string
  document: string
  updated_by: number
  currency: string
  created_by: string
  id: number
  address: {
    id: number
    city: string
    country: string
    district: string
    number: string
    observation: string
    state: string
    street: string
    zip_code: string
  } | null
  clinic_contacts:
    | [
        {
          contact: string
          ddi_country: string
          id: number
          is_main: boolean
          type_of_contact: string
        }
      ]
    | null
}

type ClinicData = DadosClinicaData | undefined

type ContactsData = {
  contact: string
  ddi_country: string
  id: string
  is_main: boolean
  type_of_contact: string
}

const ttl = expireDateNowAdd8hours()

export default function AboutClinic() {
  // options select with json
  let optionsCurrencies: any = []
  Currencies.map((currency) => {
    optionsCurrencies = [
      ...optionsCurrencies,
      { value: currency.name, label: currency.sigla }
    ]
  })

  // options select with json
  let optionsTimezone: any = []
  Fusos.map((groups) => {
    groups?.zones?.map((zone) => {
      optionsTimezone = [...optionsTimezone, zone]
    })
  })

  let optionsContries: any = []
  Countries.map((currency) => {
    optionsContries = [
      ...optionsContries,
      { value: currency.name, label: currency.name }
    ]
  })

  const { t } = useTranslation('pageClinic')
  const toast = useToast()
  const address: any = useAddress()
  const { clinicId, selectedLanguageDefault, decodeToken } = useAuth()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { register, handleSubmit, formState } = useForm<AboutClinicForm>({})
  const { errors } = formState

  // estados
  const [dadosClinica, setDadosClinica] = useState<ClinicData>(undefined)
  const [name, setName] = useState('')
  const [responsible, setResponsible] = useState('')
  const [document, setDocument] = useState('')
  const [numberAddress, setNumberAddress] = useState('')
  const [complement, setComplement] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [numbers, setNumbers] = useState<ContactsData[]>([
    {
      contact: '',
      ddi_country: '+55',
      id: lodash.uniqueId(),
      is_main: true,
      type_of_contact: 'telefone'
    }
  ])
  // controlled input
  const [seletedLang, setSelectedLang] = useState<any>({
    value: 'pt',
    label: 'Português'
  })
  const [seletedCurrency, setSelectedCurrency] = useState<any>({
    value: 'BRL',
    label: 'BRL'
  })
  const [selectedTimezone, setSelectTimezone] = useState<any>({
    value: 'America/Sao_Paulo',
    label: 'Sao Paulo'
  })

  // get InfoClinicData from apiAuth
  const getClinicDataInfo = async (id: string) => {
    setIsLoading(true)
    try {
      const { data } = await apiAuth.get(`clinics/clinics/${id}/`)

      setDadosClinica(data)
      setIsLoading(false)
    } catch (error: any) {
      const errorMessage = error.response?.data?.message

      toast({
        title: t('Error'),
        description: `${errorMessage}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  useEffect(() => {
    getClinicDataInfo(clinicId)
  }, [])

  useEffect(() => {
    if (dadosClinica !== undefined) {
      if (dadosClinica !== null) {
        if (dadosClinica?.address) {
          address.setCep(dadosClinica?.address?.zip_code || '')
          address.setAddress(dadosClinica?.address?.street || '')
          address.setBairro(dadosClinica?.address?.district || '')
          address.setMunicipio(dadosClinica?.address?.city || '')
          address.optionsContries.map((contry: any) => {
            if (dadosClinica?.address?.country === contry.label) {
              address.setMunicipio(dadosClinica?.address?.city || '')
              if (contry !== null) {
                address.setSeletedCountry(contry)
              } else {
                address.setSeletedCountry('')
              }
            }
          })
          StateOptions.map((ufAux: any) => {
            if (dadosClinica?.address?.state === ufAux.label) {
              address.setSelectedState(ufAux || '')
              address.setUfDefault(ufAux || '')
            }

            if (dadosClinica?.address?.state === ufAux.sigla) {
              address.setSelectedState(ufAux || '')
              address.setUfDefault(ufAux || '')
            }
          })
        }

        if (dadosClinica?.name) {
          setName(dadosClinica.name)
        }

        if (dadosClinica?.address?.number) {
          setNumberAddress(dadosClinica.address.number)
        }

        if (dadosClinica?.address?.observation) {
          setComplement(dadosClinica.address.observation)
        }

        if (dadosClinica?.responsible) {
          setResponsible(dadosClinica?.responsible)
        }

        if (dadosClinica?.document) {
          setDocument(dadosClinica?.document)
        }

        if (dadosClinica?.clinic_contacts !== null) {
          if (dadosClinica?.clinic_contacts?.length > 0) {
            const allNumbers: any = dadosClinica?.clinic_contacts
            setNumbers(allNumbers)
          }
        }

        switch (dadosClinica?.language) {
          case 'pt':
            setSelectedLang({
              value: 'pt',
              label: 'Português'
            })
            break
          case 'es':
            setSelectedLang({
              value: 'es',
              label: 'Español'
            })
            break

          case 'en':
            setSelectedLang({
              value: 'en',
              label: 'English'
            })
            break

          default:
            setSelectedLang({
              value: 'pt',
              label: 'Português'
            })
        }

        switch (dadosClinica?.currency) {
          case 'BRL':
            setSelectedCurrency({
              value: 'BRL',
              label: 'BRL'
            })
            break
          case 'USD':
            setSelectedCurrency({
              value: 'USD',
              label: 'USD'
            })
            break
          case 'EUR':
            setSelectedCurrency({
              value: 'EUR',
              label: 'EUR'
            })
            break
          case 'GBP':
            setSelectedCurrency({
              value: 'GBP',
              label: 'GBP'
            })
            break
          case 'JPY':
            setSelectedCurrency({
              value: 'JPY',
              label: 'JPY'
            })
            break
          case 'AUD':
            setSelectedCurrency({
              value: 'AUD',
              label: 'AUD'
            })
            break
          case 'CHF':
            setSelectedCurrency({
              value: 'CHF',
              label: 'CHF'
            })
            break
          case 'CAD':
            setSelectedCurrency({
              value: 'CAD',
              label: 'CAD'
            })
            break
          case 'ARS':
            setSelectedCurrency({
              value: 'ARS',
              label: 'ARS'
            })
            break
          case 'TRY':
            setSelectedCurrency({
              value: 'TRY',
              label: 'TRY'
            })
            break

          default:
            setSelectedCurrency({
              value: 'BRL',
              label: 'BRL'
            })
        }

        if (dadosClinica?.timezone) {
          optionsTimezone?.forEach((timezone: any) => {
            if (timezone?.value === dadosClinica?.timezone) {
              setSelectTimezone(timezone)
            }
          })
        }
      }
    }
  }, [dadosClinica])

  // select default country in configs
  useEffect(() => {
    if (decodeToken === undefined) {
      return
    }

    if (decodeToken.clinic_country === 'Brasil') {
      address.setSeledtedBR(true)
    }

    const country = [decodeToken.clinic_country]
    const ArrayCountry = address.optionsContries

    const SelectedDefault = ArrayCountry.map((count: any) =>
      country.find((cou) => {
        if (cou === count.value) {
          address.setSeletedCountry(count)
        }
      })
    )
  }, [decodeToken])

  const optionsIdiomas = [
    // { value: 'en', label: 'English' },
    // { value: 'es', label: 'Español' },
    { value: 'pt', label: 'Português' }
  ]

  // FUNCTIONS
  // SEAPORTS FUNCTIONS FORMS
  function handleAddNumbers() {
    setNumbers([
      ...numbers,
      {
        contact: lodash.uniqueId(),
        ddi_country: '+55',
        id: '',
        is_main: false,
        type_of_contact: ''
      }
    ])
  }

  function handleRemoveNumbers(index: number) {
    const values = [...numbers]
    values.splice(index, 1)
    setNumbers(values)
  }

  function handleChangeInputNumbers(
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    const values = [...numbers]

    if (values[index].ddi_country === '+55') {
      values[index].contact = mask(e.target.value, '(99) 99999-9999')
    } else {
      values[index].contact = mask(e.target.value, '9999999999999999')
    }

    setNumbers(values)
  }

  function formatContactsClinic() {
    if (numbers[0].contact.length > 0) {
      const numbersFormat = numbers.map((item) => {
        return {
          ddi_country: item.ddi_country,
          contact: item.contact,
          is_main: item.is_main,
          type_of_contact: item.type_of_contact
        }
      })

      return numbersFormat
    }

    return []
  }

  // editando clínica
  const handleAboutSubmit: SubmitHandler<AboutClinicForm> = async (values) => {
    if (name === '') {
      toast({
        title: `Atenção!`,
        description: `nome da clínica é obrigatório!`,
        duration: 3000,
        position: 'top',
        status: 'warning'
      })

      return
    }

    await new Promise((resolve) => setTimeout(resolve, 2000))

    // phone
    const clinic_contacts = formatContactsClinic()

    // body
    const data = {
      name: name !== '' ? name : null,
      responsible: responsible !== '' ? responsible : null,
      document: document !== '' ? document : null,
      language: seletedLang.value || dadosClinica?.language,
      currency: seletedCurrency.value || dadosClinica?.currency,
      timezone: selectedTimezone.value || dadosClinica?.timezone,
      clinic_contacts,
      address: {
        zip_code: address.cep || dadosClinica?.address?.zip_code,
        street: address.address || dadosClinica?.address?.street,
        number: numberAddress !== '' ? numberAddress : null,
        district: address.bairro || dadosClinica?.address?.district,
        city: address.municipio || dadosClinica?.address?.city,
        state: address.ufDefault.label || dadosClinica?.address?.state,
        country: address.seletedCountry.label || dadosClinica?.address?.country,
        observation: complement !== '' ? complement : null
      }
    }

    apiAuth
      .put(`clinics/clinics/${clinicId}/`, data)
      .then((res) => {
        toast({
          title: t('toast.title'),
          description: t('toast.description'),
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        if (data.currency) {
          ls.set<string>('@Clinik:Currency', data.currency, {
            ttl,
            encrypt: true
          })
        }

        selectedLanguageDefault(data.language)
        setDadosClinica(res.data)
      })
      .catch((res) => {})
  }

  if (isLoading) {
    return (
      <>
        <Box overflow="hidden" position="relative">
          <Header>
            <Flex
              mx="auto"
              w="100%"
              direction="row"
              d="flex"
              my="6"
              align="center"
              justify="space-around"
            >
              <Box mx="auto">
                <Text fontSize="24px" fontWeight="600" height="100%">
                  {t('title')}
                </Text>
              </Box>
            </Flex>
          </Header>
          <Sidebar />
          <Flex
            flex="1"
            mx="auto"
            as="form"
            ml={isWideVersion ? '75px' : '2'}
            mt="15px"
            mb="8px"
            mr="2"
            bg="white"
            h="cal(100vh - 60px)"
            onSubmit={handleSubmit(handleAboutSubmit)}
          >
            <Flex w="100%" h="100vh" flex="1" align="center" justify="center">
              <Spinner color="blue" />
            </Flex>
          </Flex>
        </Box>
      </>
    )
  }

  return (
    <>
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Text fontSize="24px" fontWeight="600" height="100%">
                {t('title')}
              </Text>
            </Box>
            <PermissionComponent spiCode="ABOUT_CLINIC" ipCode="EDIT">
              <Button
                type="submit"
                size="md"
                fontSize="md"
                bg="blue.300"
                color="#fff"
                isLoading={formState.isSubmitting}
                onClick={handleSubmit(handleAboutSubmit)}
              >
                {t('button')}
              </Button>
            </PermissionComponent>
          </Flex>
        </Header>
        <Sidebar />

        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
          onSubmit={handleSubmit(handleAboutSubmit)}
        >
          <Stack spacing="4" w="100%">
            <CardContainer title={t('containerOne.title')}>
              <Flex direction={['column', 'row']} w="100%">
                {/* <Flex justify="center" mb={['4', "0"]}>
                  <Dropzone />
                </Flex> */}
                <Flex flex="1" direction="column">
                  <Input
                    label={t('containerOne.iNome')}
                    isRequired
                    name="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    error={errors.name}
                  />

                  <Stack
                    direction={['column', 'row']}
                    my={['0', '2']}
                    mt={['4', '2']}
                    spacing={['', '6']}
                  >
                    <Input
                      label={t('containerOne.iResponsible')}
                      name="responsible"
                      value={responsible}
                      onChange={(event) => setResponsible(event.target.value)}
                      error={errors.responsible}
                    />
                    <Input
                      label={t('containerOne.iDocumento')}
                      name="document"
                      value={document}
                      onChange={(event) => setDocument(event.target.value)}
                      error={errors.doc}
                    />
                  </Stack>
                </Flex>
              </Flex>
            </CardContainer>

            <CardContainer title={t('containerTwo.title')}>
              <Stack my="2" direction={['column', 'row']}>
                <Box w="100%">
                  <SelectComponent
                    title={t('containerTwo.iLingua')}
                    placeholder="Selecione uma lingua"
                    options={optionsIdiomas}
                    value={seletedLang}
                    onChange={(e: any) => setSelectedLang(e)}
                    name="language"
                  />
                </Box>
                <Box w="100%">
                  <SelectComponent
                    title={t('containerTwo.iMoeda')}
                    placeholder="Selecione uma moeda"
                    options={optionsCurrencies}
                    value={seletedCurrency}
                    onChange={(e: any) => setSelectedCurrency(e)}
                    name="moeda"
                  />
                </Box>
                <Box w="100%">
                  <SelectComponent
                    title={t('containerTwo.iFuso')}
                    placeholder="Selecione o fuso horário"
                    options={optionsTimezone}
                    value={selectedTimezone}
                    onChange={(e: any) => setSelectTimezone(e)}
                    name="moeda"
                  />
                </Box>
              </Stack>
            </CardContainer>

            <CardContainer title={t('containerThree.title')}>
              <Stack direction="row" my="2" spacing="2">
                <Box w="30%">
                  <SelectComponent
                    title={t('containerThree.iPais')}
                    placeholder="Selecione um país"
                    options={address.optionsContries}
                    name="pais"
                    value={address.seletedCountry}
                    onChange={(e: any) =>
                      address.handleBRSelectedAndShowStates(e)
                    }
                  />
                </Box>
                <Box>
                  <Input
                    label={t('containerThree.iCEP')}
                    {...register('cep')}
                    value={address.cep}
                    onChange={(e) =>
                      address.handleCompletAddress(e.target.value)
                    }
                  />
                </Box>
                <Box w="40%">
                  <Input
                    label={t('containerThree.iRua')}
                    {...register('rua')}
                    value={address.address}
                    onChange={(e) => address.setAddress(e.target.value)}
                  />
                </Box>
                <Box>
                  <Input
                    label={t('containerThree.iNumero')}
                    name="number"
                    value={numberAddress}
                    onChange={(event) => setNumberAddress(event.target.value)}
                  />
                </Box>
              </Stack>
              <Stack direction="row" my="2" spacing="2">
                <Box w="30%">
                  <Input
                    label={t('containerThree.iComplemento')}
                    name="complementAddress"
                    value={complement}
                    onChange={(event) => setComplement(event.target.value)}
                  />
                </Box>
                <Box>
                  <Input
                    label={t('containerThree.iBairro')}
                    {...register('bairro')}
                    value={address.bairro}
                    onChange={(e) => address.setBairro(e.target.value)}
                  />
                </Box>
                <Box w="30%">
                  <Input
                    label={t('containerThree.iCidade')}
                    {...register('cidade')}
                    value={address.municipio}
                    onChange={(e) => address.setMunicipio(e.target.value)}
                  />
                </Box>
                {address.selectedBR ? (
                  <Box w="30%">
                    <SelectComponent
                      title={t('containerThree.iEstado')}
                      placeholder="Selecione um estado"
                      options={address.ufs}
                      name="estado"
                      value={address.seletedState}
                      onChange={(e: any) => address.setSelectedState(e)}
                    />
                  </Box>
                ) : (
                  <Box w="30%">
                    <Input
                      label={t('containerThree.iEstado')}
                      {...register('estado')}
                      defaultValue={dadosClinica?.address?.state}
                      error={errors.estado}
                    />
                  </Box>
                )}
              </Stack>
            </CardContainer>

            <CardContainer title={t('containerFour.title')}>
              <Stack my="2" spacing="6">
                {numbers.map((number, index: number) => (
                  <Stack direction="row" key={`number${index}`}>
                    <Stack w="50%" direction="row">
                      <InputPhone2
                        label={`${t('containerFour.phone')} ${index + 1}`}
                        type="text"
                        size="md"
                        name="number"
                        defaultValues={{
                          ddi: numbers[index].ddi_country
                        }}
                        getValues={(value) => {
                          const values = [...numbers]
                          values[index].ddi_country = value?.ddi_country
                          setNumbers(values)
                        }}
                        value={numbers[index].contact}
                        onChange={(e) => handleChangeInputNumbers(index, e)}
                      />
                    </Stack>

                    {/*                     <Flex
                      pt="4"
                      direction="column"
                      align="center"
                      justify="center"
                    >
                      {numbers.length > 1 && (
                        <Box>
                          <Button
                            type="button"
                            colorScheme="red"
                            size="xs"
                            onClick={() => handleRemoveNumbers(index)}
                            mb="2"
                          >
                            -
                          </Button>
                        </Box>
                      )}
                    </Flex> */}
                  </Stack>
                ))}
              </Stack>
              {/*               <Box mt="3">
                <Button
                  size="md"
                  fontSize="sm"
                  bg="green.300"
                  color="#fff"
                  isLoading={formState.isSubmitting}
                  onClick={() => handleAddNumbers()}
                >
                  {t('containerFour.btnAddPhone')}
                </Button>
              </Box> */}
            </CardContainer>

            <Flex w="100%" h="200px" />
          </Stack>
        </Flex>
      </Box>
    </>
  )
}
