/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Input } from '../../components/Form/Input'
import { Header } from '../../components/Header'
import { CardContainer } from '../../components/molecules/CardContainer'
import { Sidebar } from '../../components/Sidebar'

import { SelectComponent } from '../../components/Form/SelectComponent'
import { SelectComponentAsync } from '../../components/Form/SelectComponentAsync'
import { Switch } from '../../components/Form/Switch'
import { useAttendance } from './service'
import format from 'date-fns/format'
import { useHistory } from 'react-router-dom'

type FormData = {
  is_active: boolean
  description: string
}

type Actions = {
  label: string
  value: string
}

type DefaultFormat = {
  label: string
  value: number
}

export function CreateNote() {
  const [t] = useTranslation('pageNotes')
  const router = useHistory()
  const { state }: any = router.location

  const attendanceService = useAttendance()
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const optionsDefaultTypes = [
    {
      value: '1',
      label: `${t('types.1')}`
    },
    {
      value: '2',
      label: `${t('types.2')}`
    },
    {
      value: '3',
      label: `${t('types.3')}`
    },
    {
      value: '4',
      label: `${t('types.4')}`
    },
    {
      value: '5',
      label: `${t('types.5')}`
    }
  ]
  // state
  const [name, setName] = useState('')
  const [choice, setChoice] = useState<DefaultFormat | null>(null)
  const [eventDate, setEventDate] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [type, setType] = useState<Actions>({
    value: '1',
    label: `${t('types.1')}`
  })

  useEffect(() => {
    if (state?.patient) {
      setChoice(state?.patient)
    }
  }, [])

  const handlerChoiceChangesValues = () => {
    if (type.value === '2') {
      const specialists = attendanceService.specialists.map((specialist) => {
        return {
          value: specialist.id,
          label: specialist.social_name
        }
      })

      return specialists
    }

    if (type.value === '3') {
      const users = attendanceService.users.map((user) => {
        return {
          value: user.id,
          label: user.user.name
        }
      })

      return users
    }

    if (type.value === '4') {
      const equipments = attendanceService.equipments.map((equipment) => {
        return {
          value: equipment.id,
          label: equipment.name
        }
      })

      return equipments
    }

    return []
  }

  const handlerPlaceholderChangesValues = () => {
    if (type.value === '2') {
      return `${t('types.2')}`
    }

    if (type.value === '3') {
      return `${t('types.3')}`
    }

    if (type.value === '4') {
      return `${t('types.4')}`
    }

    return `${t('types.5')}`
  }

  const handleKeyUpEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = event.target.value.toUpperCase()
    setName(values)
  }

  const handleSelectType = (event: any) => {
    setType(event)
    setChoice(null)
  }

  const handlerSelectChoice = (event: any) => {
    setChoice(event)
  }

  const handleCreateNote: SubmitHandler<FormData> = async (values) => {
    let data: object = {}
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const { is_active, description } = values

    if (type.value === '1' && choice !== null) {
      data = {
        patients_ids: [choice?.value]
      }
    }

    if (type.value === '2' && choice !== null) {
      data = {
        specialists_ids: [choice?.value]
      }
    }

    if (type.value === '3' && choice !== null) {
      data = {
        users_ids: [choice?.value]
      }
    }

    if (type.value === '4' && choice !== null) {
      data = {
        equipments_ids: [choice?.value]
      }
    }
    const payload = {
      name: new Date().toISOString(),
      is_active,
      message: description === '' ? null : description,
      event_date:
        eventDate === ''
          ? format(new Date(), 'YYYY-MM-DDThh:mm')
          : `${eventDate}T00:00`,
      type: Number(type?.value),
      ...data
    }

    attendanceService.createNote(payload, state?.back)
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          display="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('create.title')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href={state?.back ?? '/notes'}>
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateNote)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              {/* <Box w="50%" mr="2">
                <Input
                  label={t('create.container1.i1')}
                  name="name"
                  fontSize="16"
                  placeholder={t('placeholder.name')}
                  isRequired
                  value={name}
                  onChange={handleKeyUpEvent}
                  maxLength={30}
                />
              </Box> */}
              <Stack direction="row" my="2" spacing="4" w="50%">
                <Box w="300px" mr="2">
                  <SelectComponent
                    options={optionsDefaultTypes}
                    name="type"
                    value={type}
                    onChange={handleSelectType}
                    title={t('label.type')}
                    placeholder={t('placeholder.recipes')}
                  />
                </Box>
                <Box w="300px" mr="2">
                  <Input
                    label={t('create.container1.i4')}
                    type="date"
                    fontSize="16"
                    name="eventDate"
                    value={eventDate}
                    onChange={(e) => setEventDate(e.target.value)}
                    isRequired
                    min={format(new Date(), 'yyyy-MM-dd')}
                  />
                </Box>
                <Box ml="12">
                  <Switch
                    defaultChecked
                    label={t('create.container1.i2')}
                    {...register('is_active')}
                  />
                </Box>
              </Stack>
            </Stack>
            <Flex direction="column">
              <Flex direction="row" gap="3px">
                <Text mb="2" fontSize="sm">
                  {t('create.container1.i5')}
                </Text>
                <Text color="red.500">*</Text>
              </Flex>
              <Textarea
                placeholder={t('create.container1.description')}
                {...register('description')}
                isRequired
                maxLength={500}
              />
            </Flex>
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            <Stack direction="column" my="2" spacing="6" w="100%">
              {type.value === '1' ? (
                <SelectComponentAsync
                  loadOptions={(values: string) =>
                    attendanceService.getClients(values)
                  }
                  value={choice}
                  title={t('create.container2.i1')}
                  onChange={handlerSelectChoice}
                  name="specialists"
                  placeholder={t('create.container2.patient')}
                />
              ) : (
                <SelectComponent
                  options={handlerChoiceChangesValues()}
                  value={choice}
                  title={t('create.container2.i1')}
                  onChange={handlerSelectChoice}
                  name="specialists"
                  placeholder={handlerPlaceholderChangesValues()}
                />
              )}
            </Stack>
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </Flex>
    </Box>
  )
}
