import { ReactNode } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button
} from '@chakra-ui/react'

interface ModalFiltersProps {
  children: ReactNode
  title: string
  applyFilter: () => void
  isHaveDefault?: boolean
  isOpen: boolean
  removeFilter?: () => void
  onClose: () => void
  size?: 'full' | 'md' | 'lg' | '4xl'
}

export const ModalFilters = ({
  children,
  title,
  applyFilter,
  removeFilter,
  isOpen,
  onClose,
  isHaveDefault,
  size = 'full'
}: ModalFiltersProps) => {
  return (
    <Modal size={size} key={title} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent overflowY="auto">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button
            variant="ghost"
            mr={3}
            onClick={() => {
              removeFilter?.()
            }}
          >
            {isHaveDefault ? 'Config. padrão' : 'Limpar'}
          </Button>
          <Button colorScheme="blue" onClick={() => applyFilter()}>
            Aplicar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
