import { Icon, Stack, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { BsClipboardData } from 'react-icons/bs'
import { FaCog, FaDollarSign } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { GoCalendar } from 'react-icons/go'
import { GrDocumentStore } from 'react-icons/gr'
import { GiReceiveMoney, GiPayMoney } from 'react-icons/gi'
import { MdInventory, MdLocalHospital, MdSpeakerNotes } from 'react-icons/md'
import { SiWhatsapp } from 'react-icons/si'
import { PermissionComponent } from '../Permissions'
import { NavLink } from './NavLink'
import { NavSection } from './NavSection'

interface SidebarProps {
  isMouseHover?: boolean
}

export const SidebarNav: React.FC<SidebarProps> = ({ isMouseHover }) => {
  const [t] = useTranslation('sidebar')

  return (
    <Stack spacing="6" align="flex-start">
      <NavLink to="/scheduling" icon={GoCalendar}>
        {isMouseHover ? `${t('sidebar.scheduling.app')}` : ''}
      </NavLink>
      <NavLink to="/clients" icon={FiUsers}>
        {isMouseHover ? `${t('sidebar.clientes.app')}` : ''}
      </NavLink>
      <NavLink to="/notes" icon={MdSpeakerNotes}>
        {isMouseHover ? `${t('sidebar.notes.app')}` : ''}
      </NavLink>
      <PermissionComponent
        group={[
          'BILLS_TO_PAY',
          'BILLS_TO_RECEIVE',
          'FISCAL_CONTROL',
          'ENTITIES',
          'CONCILIATION'
        ]}
      >
        <NavSection
          title={isMouseHover ? `${t('sidebar.InFinances.app')}` : ''}
          icon={GiReceiveMoney}
          isMouseHover={isMouseHover}
        >
          <PermissionComponent spiCode="BILLS_TO_RECEIVE" ipCode="VIEW">
            <NavLink to="/financial/daily-box">
              {isMouseHover ? `${t('sidebar.dailyBox.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="BILLS_TO_RECEIVE" ipCode="VIEW">
            <NavLink to="/financial/tickets-canceled">
              {isMouseHover ? `${t('sidebar.ticketsCanceled.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="BILLS_TO_RECEIVE" ipCode="VIEW">
            <NavLink to="/financial/billy-to-receive">
              {isMouseHover ? `${t('sidebar.billsToReceive.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="CONCILIATION" ipCode="VIEW">
            <NavLink to="/financial/conciliation">
              {isMouseHover ? `${t('sidebar.conciliation.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="FISCAL_CONTROL" ipCode="VIEW">
            <NavLink to="/financial/tax/control">
              {t('sidebar.taxControl.app')}
            </NavLink>
          </PermissionComponent>
        </NavSection>
      </PermissionComponent>
      <PermissionComponent
        group={[
          'BILLS_TO_PAY',
          'BILLS_TO_RECEIVE',
          'FISCAL_CONTROL',
          'ENTITIES',
          'CONCILIATION'
        ]}
      >
        <NavSection
          title={isMouseHover ? `${t('sidebar.outFinances.app')}` : ''}
          icon={GiPayMoney}
          isMouseHover={isMouseHover}
        >
          <PermissionComponent spiCode="BILLS_TO_PAY" ipCode="VIEW">
            <NavLink to="/finances/bills-to-pay">
              {isMouseHover ? `${t('sidebar.bills-to-pay.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="ENTITIES" ipCode="VIEW">
            <NavLink to="/financial/suppliers">
              {isMouseHover ? `${t('sidebar.suppliers.app')}` : ''}
            </NavLink>
          </PermissionComponent>
        </NavSection>
      </PermissionComponent>
      {/* <PermissionComponent group={['CASH_FLOW']}>
        <NavSection
          title={isMouseHover ? `${t('sidebar.financesReport.app')}` : ''}
          icon={BsClipboardData}
          isMouseHover={isMouseHover}
        >
          <PermissionComponent spiCode="CASH_FLOW" ipCode="VIEW">
            <NavLink to="/financial/analytical/realized-cash-flow">
              {isMouseHover ? `${t('sidebar.realizeCashFlow.app')}` : ''}
            </NavLink>
          </PermissionComponent>
        </NavSection>
      </PermissionComponent> */}
      <PermissionComponent group={['PRODUCTS', 'PRODUCT_GROUPS']}>
        <NavSection
          title={isMouseHover ? `${t('sidebar.products.app')}` : ''}
          icon={MdInventory}
          isMouseHover={isMouseHover}
        >
          {/* <PermissionComponent spiCode="PRODUCTS" ipCode="VIEW">
            <NavLink to="/items-resale">
              {isMouseHover ? `${t('sidebar.itemsResale.app')}` : ''}
            </NavLink>
          </PermissionComponent> */}
          <PermissionComponent spiCode="PRODUCTS" ipCode="VIEW">
            <NavLink to="/items-products">
              {isMouseHover ? `${t('sidebar.items.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          {/* <PermissionComponent spiCode="TRANSACTION_HISTORY" ipCode="VIEW">
            <NavLink to="/history-products">
              {isMouseHover ? `${t('sidebar.movement.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="BALANCE" ipCode="VIEW">
            <NavLink to="/">
              {isMouseHover ? `${t('sidebar.balance.app')}` : ''}
            </NavLink>
          </PermissionComponent> */}
          <PermissionComponent spiCode="PRODUCT_GROUPS" ipCode="VIEW">
            <NavLink to="/group-products">
              {isMouseHover ? `${t('sidebar.groups.app')}` : ''}
            </NavLink>
          </PermissionComponent>
        </NavSection>
      </PermissionComponent>
      <PermissionComponent
        group={['SERVICES', 'SERVICES_DOCUMENTS', 'SERVICE_GROUPS']}
      >
        <NavSection
          title={isMouseHover ? `${t('sidebar.services.app')}` : ''}
          icon={MdLocalHospital}
          isMouseHover={isMouseHover}
        >
          <PermissionComponent spiCode="SERVICES" ipCode="VIEW">
            <NavLink to="/services">
              {isMouseHover ? `${t('sidebar.services.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="SERVICE_GROUPS" ipCode="VIEW">
            <NavLink to="/services/tags">
              {isMouseHover ? `${t('sidebar.servicesTags.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="SERVICES_DOCUMENTS" ipCode="VIEW">
            <NavLink to="/services/document-service">
              {isMouseHover ? `${t('sidebar.servicesDocs.app')}` : ''}
            </NavLink>
          </PermissionComponent>
        </NavSection>
      </PermissionComponent>
      <PermissionComponent group={['TRANSFER_REPORTS']}>
        <NavSection
          title={isMouseHover ? `${t('sidebar.reports.app')}` : ''}
          icon={GrDocumentStore}
          isMouseHover={isMouseHover}
        >
          {/* <PermissionComponent spiCode="TRANSFER_REPORTS" ipCode="VIEW">
            <NavLink to="/settings/reports/transfer">
              {t('sidebar.reportTransfer.app')}
            </NavLink>
          </PermissionComponent> */}
          <PermissionComponent spiCode="TRANSFER_REPORTS" ipCode="VIEW">
            <NavLink to="/settings/reports/indications">
              {t('sidebar.reportsIndications.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="TRANSFER_REPORTS" ipCode="VIEW">
            <NavLink to="/settings/reports/financial">
              {t('sidebar.reportsFinancial.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="TRANSFER_REPORTS" ipCode="VIEW">
            <NavLink to="/settings/reports/services-and-products">
              {t('sidebar.reportsServicesAndProducts.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="TRANSFER_REPORTS" ipCode="VIEW">
            <NavLink to="/settings/reports/marketing">
              {t('sidebar.reportsMarketing.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="TRANSFER_REPORTS" ipCode="VIEW">
            <NavLink to="/settings/reports/operation">
              {t('sidebar.reportsOperation.app')}
            </NavLink>
          </PermissionComponent>
        </NavSection>
      </PermissionComponent>
      <PermissionComponent
        group={[
          'ABOUT_CLINIC',
          'ATTENDANCES_SPOTS',
          'CUSTOMER_ORIGENS',
          'CUSTOMER_TAGS',
          'PRINT_TEMPLATES',
          'EXAMS',
          'FORMS',
          'INSURANCES',
          'USERS',
          'PRESCRIPTIONS',
          'WAYS_TO_RECEIVE',
          'WAYS_TO_PAYMENTS',
          'COST_CENTERS',
          'FISCAL_UNITS',
          'PREFERENCES'
        ]}
      >
        <NavSection
          title={isMouseHover ? `${t('sidebar.configuracao.app')}` : ''}
          icon={FaCog}
          isMouseHover={isMouseHover}
        >
          <PermissionComponent spiCode="ABOUT_CLINIC" ipCode="VIEW">
            <NavLink to="/settings/block-times">
              {t('sidebar.blockTimes.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="PRESCRIPTIONS" ipCode="VIEW">
            <NavLink to="/settings/recipes">{t('sidebar.recipes.app')}</NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="EXAMS" ipCode="VIEW">
            <NavLink to="/settings/exam-request">
              {t('sidebar.examRequest.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="FORMS" ipCode="VIEW">
            <NavLink to="/settings/forms">
              {t('sidebar.creatingForms.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="FORMS" ipCode="VIEW">
            <NavLink to="/settings/template">
              {t('sidebar.templates.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="PREFERENCES" ipCode="VIEW">
            <NavLink to="/settings/required-fields">
              {t('sidebar.camposObrigatorios.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="CUSTOMER_ORIGENS" ipCode="VIEW">
            <NavLink to="/settings/equipments">
              {t('sidebar.equipments.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="INSURANCES" ipCode="VIEW">
            <NavLink to="/settings/covenants">
              {t('sidebar.convenios.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="CUSTOMER_ORIGENS" ipCode="VIEW">
            <NavLink to="/settings/origins">{t('sidebar.origins.app')}</NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="CUSTOMER_TAGS" ipCode="VIEW">
            <NavLink to="/settings/tags">{t('sidebar.tags.app')}</NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="USERS" ipCode="VIEW">
            <NavLink to="/settings/users">{t('sidebar.usuarios.app')}</NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="WAYS_TO_RECEIVE" ipCode="VIEW">
            <NavLink to="/financial/receiving-method">
              {isMouseHover ? `${t('sidebar.meansOfReceiving.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="WAYS_TO_PAYMENTS" ipCode="VIEW">
            <NavLink to="/financial/payment-sources">
              {isMouseHover ? `${t('sidebar.payment-sources.app')}` : ''}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="COST_CENTERS" ipCode="VIEW">
            <NavLink to="/financial/cost-centers">
              {t('sidebar.cost-centers.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="FISCAL_UNITS" ipCode="VIEW">
            <NavLink to="/financial/tax/control/unit">
              {t('sidebar.unitTaxControl.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="ATTENDANCES_SPOTS" ipCode="VIEW">
            <NavLink to="/settings/attendance">
              {t('sidebar.locais.app')}
            </NavLink>
          </PermissionComponent>
          <PermissionComponent spiCode="ABOUT_CLINIC" ipCode="VIEW">
            <NavLink to="/settings/about-clinic">
              {t('sidebar.clinica.app')}
            </NavLink>
          </PermissionComponent>
        </NavSection>
      </PermissionComponent>
      <a
        href="https://api.whatsapp.com/send?phone=5586994486936&text=Me%20chame"
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
        target="_blank"
        rel="noreferrer"
      >
        <Icon as={SiWhatsapp} fontSize="18px" mt="2px" />
        {isMouseHover && (
          <Text ml="4" fontSize="14px">
            {t('sidebar.suporte.app')}
          </Text>
        )}
      </a>
    </Stack>
  )
}
