/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
  Flex,
  useToast,
  Stack,
  Checkbox
} from '@chakra-ui/react'
import React, { useEffect, useState, useCallback } from 'react'
import { useQuery } from 'react-query'
import { FiCheck, FiDelete, FiEdit } from 'react-icons/fi'

import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getNotes, NotesData } from '../../hooks/notes/useNotes'
import {} from '../../contexts/ModalContext'
import { LayoutList } from '../../layouts/List'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { PermissionComponent } from '../../components/Permissions'
import { apiAuth } from '../../services/apiAuth'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { expireDateVerify } from '../../utils/fns/expireDate'
import { useModal } from '../../hooks/useModal'
import { ModalFilters } from '../../components/Modal'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { useAttendance } from './service'

// debounce
let timer: any = null

export function Notes() {
  const [t] = useTranslation('pageNotes')
  const services = useAttendance()
  const {
    searchNotes,
    handlerCloseModal,
    handlerApplyFiltersNotes,
    specialistsNotes,
    typesNotes,
    usersNotes,
    isOpenNotes,
    setSearchNotes,
    equipmentsNotes,
    handlerRemoveFiltersNotes
  } = useModal()
  const toast = useToast()
  const router = useHistory()
  const [currentPage, setCurrentPage] = useState(1)
  const [noteIdExclude, setNoteIdExclude] = useState(0)
  const [isOpenExclude, setIsOpenExclude] = useState(false)
  const [isActive, setIsActive] = useState(true)
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  const [typesState, setTypesState] = useState<any>(typesNotes)
  const [specialistsState, setSpecialistsState] =
    useState<any>(specialistsNotes)
  const [usersState, setUsersState] = useState<any>(usersNotes)
  const [equipmentsState, setEquipmentsState] = useState<any>(equipmentsNotes)
  const [patients, setPatients] = useState<any>([])
  const [isBlocked, setIsBlocked] = useState(false)

  const optionsDefaultTypes = [
    {
      value: '1',
      label: `${t('types.1')}`
    },
    {
      value: '2',
      label: `${t('types.2')}`
    },
    {
      value: '3',
      label: `${t('types.3')}`
    },
    {
      value: '4',
      label: `${t('types.4')}`
    },
    {
      value: '5',
      label: `${t('types.5')}`
    }
  ]

  const handlerRemoveFiltersNotesAndPatients = () => {
    handlerRemoveFiltersNotes()
    setPatients([])
  }

  const { state }: any = router.location

  useEffect(() => {
    if (state?.patient) {
      setPatients([state?.patient?.value])
    }
  }, [state])

  useEffect(() => {
    if (typesState?.length > 1) {
      setSpecialistsState([])
      setUsersState([])
      setEquipmentsState([])
      setIsBlocked(true)
    } else {
      setIsBlocked(false)
    }

    return () => {}
  }, [typesState])

  const {
    data: getNotesData,
    isFetching,
    refetch,
    isLoading
  } = useQuery(
    [
      'notes',
      currentPage,
      searchNotes,
      isActive,
      patients,
      typesNotes,
      specialistsNotes,
      equipmentsNotes,
      usersNotes
    ],
    () =>
      getNotes(
        currentPage,
        searchNotes,
        isActive,
        patients,
        typesNotes,
        specialistsNotes,
        equipmentsNotes,
        usersNotes
      )
  )

  useEffect(() => {
    refetch()
  }, [])

  useEffect(() => {
    refetch()
  }, [isActive])

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchNotes(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  const deleteNoteById = async (id: number) => {
    const { status } = await apiAuth.delete(`clinics/notes/${id}/`)

    try {
      if (status === 204) {
        toast({
          description: `Lembrete excluído com sucesso`,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        refetch()

        setIsOpenExclude(false)
      }
    } catch (error) {
      toast({
        description: `Não foi possível excluir o lembrete`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  const handlerCloseModalNotes = () => {
    handlerCloseModal('Notes')
  }

  const handlerCheckedNote = async (note: NotesData, isChecked: boolean) => {
    const equipments = note?.equipments?.map((equip) => equip.id)
    const users = note?.users?.map((user) => user.id)
    const specialists = note?.specialists?.map((specialist) => specialist.id)
    const patients = note?.patients?.map((patient) => patient.id)

    const { status } = await apiAuth.patch(`clinics/notes/${note.id}/`, {
      ...note,
      is_cheked: isChecked,
      patients_ids: patients || [],
      specialists_ids: specialists || [],
      equipments_ids: equipments || [],
      users_ids: users || []
    })

    try {
      if (status === 200) {
        toast({
          description: `Status do lembrete alterado com sucesso`,
          status: 'success',
          duration: 3000,
          isClosable: true
        })
        refetch()
      }
    } catch (error) {
      toast({
        description: `Não foi possível alterar o status do lembrete`,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  return (
    <LayoutList
      title={t('title')}
      urlNew="/notes/create"
      refetch={refetch}
      isFetching={isFetching}
      placeholder={t('search')}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      spiCode="NOTES"
      isHaveFilter
      defaultSearch={searchNotes}
      nameFilter="Notes"
    >
      <Box>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              {/* <Th>{t('t1')}</Th> */}
              <Th>{t('t2')}</Th>
              <Th>{t('t3')}</Th>
              <Th>{t('t4')}</Th>
              <Th>{t('t5')}</Th>
              <Th>{t('t6')}</Th>
              <Th>{t('t7')}</Th>
              <Th>{t('t8')}</Th>
              <Th>{t('t9')}</Th>
              <Th>{t('t10')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr key={skeleton}>
                    {/* <Td>
                      <Skeleton height="20px" />
                    </Td> */}
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getNotesData?.notes.map((note) => (
                  <Tr key={note.id} maxHeight="40px">
                    {/* <Td>
                      <Text fontSize="sm">{note.name}</Text>
                    </Td> */}

                    <Td>
                      <Text fontSize="sm">
                        {note.type === 1 && `${t('types.1')}`}
                        {note.type === 2 && `${t('types.2')}`}
                        {note.type === 3 && `${t('types.3')}`}
                        {note.type === 4 && `${t('types.4')}`}
                        {note.type === 5 && `${t('types.5')}`}
                      </Text>
                    </Td>

                    <Td>
                      {note.type === 1 &&
                        note.patients?.length > 0 &&
                        note?.patients?.map((item) => (
                          <Tooltip
                            key={item?.id}
                            label={item?.social_name ?? item?.name ?? ''}
                            aria-label={item?.social_name ?? item?.name ?? ''}
                            hasArrow
                            placement="top-start"
                          >
                            <Link
                              to={{
                                pathname: `/clients/update/${item.id}`
                              }}
                            >
                              <Text
                                noOfLines={1}
                                fontSize="xs"
                                _hover={{
                                  textDecoration: 'underline'
                                }}
                              >
                                {item?.name ?? item?.social_name}
                              </Text>
                            </Link>
                          </Tooltip>
                        ))}
                      {note.type === 2 && note.specialists?.length > 0 && (
                        <Tooltip>
                          <Box>
                            <Text
                              fontSize="sm"
                              noOfLines={1}
                              _hover={{ cursor: 'default' }}
                            >
                              {note.specialists
                                .map((specialist) => specialist.social_name)
                                .join(', ')}
                            </Text>
                          </Box>
                        </Tooltip>
                      )}
                      {note.type === 3 && note.users?.length > 0 && (
                        <Tooltip>
                          <Box>
                            <Text
                              fontSize="sm"
                              noOfLines={1}
                              _hover={{ cursor: 'default' }}
                            >
                              {note.users
                                .map((user) => user.social_name)
                                .join(', ')}
                            </Text>
                          </Box>
                        </Tooltip>
                      )}
                      {note.type === 4 && note.equipments?.length > 0 && (
                        <Tooltip>
                          <Box>
                            <Text
                              fontSize="sm"
                              noOfLines={1}
                              _hover={{ cursor: 'default' }}
                            >
                              {note.equipments
                                .map((equipment) => equipment.name)
                                .join(', ')}
                            </Text>
                          </Box>
                        </Tooltip>
                      )}
                    </Td>

                    <Td maxW="400px">
                      <Tooltip
                        label={note.message || ''}
                        aria-label="A tooltip"
                        hasArrow
                        placement="top-start"
                      >
                        <Box>
                          <Text
                            fontSize="sm"
                            noOfLines={1}
                            _hover={{ cursor: 'default' }}
                          >
                            {note.message || ''}
                          </Text>
                        </Box>
                      </Tooltip>
                    </Td>

                    <Td>
                      <Text
                        fontSize="sm"
                        color={expireDateVerify(note.event_date)}
                      >
                        {note.event_date_format || ''}
                      </Text>
                    </Td>

                    <Td>
                      <Text fontSize="sm">{note.created_at || ''}</Text>
                    </Td>

                    <Td>
                      <Text fontSize="sm">{note.updated_at || ''}</Text>
                    </Td>

                    <Td>
                      <Tooltip
                        label={note.created_by_name || ''}
                        aria-label="A tooltip"
                        hasArrow
                        placement="top-start"
                      >
                        <Box>
                          <Text
                            fontSize="sm"
                            noOfLines={1}
                            _hover={{ cursor: 'default' }}
                          >
                            {note.created_by_name || ''}
                          </Text>
                        </Box>
                      </Tooltip>
                    </Td>

                    <Td>
                      <Tooltip
                        label={note.updated_by_name || ''}
                        aria-label="A tooltip"
                        hasArrow
                        placement="top-start"
                      >
                        <Box>
                          <Text
                            fontSize="sm"
                            noOfLines={1}
                            _hover={{ cursor: 'default' }}
                          >
                            {note.updated_by_name || ''}
                          </Text>
                        </Box>
                      </Tooltip>
                    </Td>

                    <Td>
                      <Flex align="center" justify="center" gap={4}>
                        {note?.is_cheked ? (
                          <Tooltip
                            label="Lembrado - ok"
                            aria-label="Lembrado - ok"
                            hasArrow
                            placement="top-start"
                          >
                            <Box>
                              <FiCheck size={16} color="#2cf321" />
                            </Box>
                          </Tooltip>
                        ) : (
                          <Checkbox
                            name="cheked"
                            onChange={(e) => {
                              handlerCheckedNote(note, e.target.checked)
                            }}
                          />
                        )}
                        <PermissionComponent spiCode="NOTES" ipCode="EDIT">
                          <Link
                            to={{
                              pathname: `/notes/update/${note.id}`,
                              state: note
                            }}
                          >
                            <Tooltip
                              label="Editar"
                              aria-label="editar"
                              hasArrow
                              placement="top-start"
                            >
                              <Box>
                                <FiEdit
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                        </PermissionComponent>
                        <PermissionComponent ipCode="DELETE" spiCode="NOTES">
                          <Tooltip
                            label="Deletar"
                            aria-label="deletar"
                            hasArrow
                            placement="top-start"
                          >
                            <Box
                              onClick={() => {
                                setIsOpenExclude(true)
                                setNoteIdExclude(note.id)
                              }}
                            >
                              <FiDelete
                                color="#990e0e"
                                size={16}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          </Tooltip>
                        </PermissionComponent>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
        {getNotesData?.totalCountOfPage === 0 && !isLoading && <NotFound />}
      </Box>
      {!(getNotesData?.totalCountOfPage === 0 && !isLoading) && (
        <Pagination
          totalCountOfRegisters={getNotesData?.totalCountOfPage ?? 0}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
      <ModalConfirmExclude
        title={t('exclude.title')}
        isOpen={isOpenExclude}
        onClose={() => setIsOpenExclude(false)}
        onConfirmExclude={() => deleteNoteById(noteIdExclude)}
      >
        <Flex>
          <Text>
            {t('exclude.description')}
            <br />
            <strong>{t('exclude.action')}</strong>
          </Text>
        </Flex>
      </ModalConfirmExclude>
      <ModalFilters
        onClose={() => {
          handlerCloseModalNotes()
        }}
        title={t('filters.title')}
        isOpen={isOpenNotes}
        size="4xl"
        applyFilter={() => {
          handlerApplyFiltersNotes({
            types: typesState,
            equipments: equipmentsState,
            specialists: specialistsState,
            users: usersState
          })
          handlerCloseModalNotes()
          setCurrentPage(1)
        }}
        removeFilter={() => {
          handlerRemoveFiltersNotesAndPatients()
          setCurrentPage(1)
        }}
      >
        <Stack w="100%" p="4" dir="column" h="500px">
          <Flex w="100%" justifyContent="flex-start" gap={4}>
            <MultiSelectMenuFilter
              label={t('filters.types')}
              options={optionsDefaultTypes?.map((type) => {
                return {
                  id: type.value,
                  name: type.label
                }
              })}
              backChange={(values) => {
                setTypesState(values)
              }}
              render={() => {
                const backValuesRender: any = typesNotes
                return backValuesRender
              }}
            />
            {typesState?.length === 1 && typesState[0] === '2' && (
              <MultiSelectMenuFilter
                label={t('filters.specialists')}
                disabled={isBlocked}
                options={services?.specialists.map((type) => {
                  return {
                    id: String(type.user.id),
                    name: type.social_name
                  }
                })}
                backChange={(values) => {
                  setSpecialistsState(values)
                }}
                render={() => {
                  const backValuesRender: any = specialistsNotes
                  return backValuesRender
                }}
              />
            )}
            {typesState?.length === 1 && typesState[0] === '3' && (
              <MultiSelectMenuFilter
                label={t('filters.users')}
                disabled={isBlocked}
                options={services?.users?.map((type) => {
                  return {
                    id: String(type.user.id),
                    name: type?.social_name ?? type.user.name
                  }
                })}
                backChange={(values) => {
                  setUsersState(values)
                }}
                render={() => {
                  const backValuesRender: any = usersNotes
                  return backValuesRender
                }}
              />
            )}
            {typesState?.length === 1 && typesState[0] === '4' && (
              <MultiSelectMenuFilter
                label={t('filters.equipments')}
                disabled={isBlocked}
                options={services?.equipments?.map((type) => {
                  return {
                    id: String(type.id),
                    name: type.name
                  }
                })}
                backChange={(values) => {
                  setEquipmentsState(values)
                }}
                render={() => {
                  const backValuesRender: any = equipmentsNotes
                  return backValuesRender
                }}
              />
            )}
          </Flex>
        </Stack>
      </ModalFilters>
    </LayoutList>
  )
}
