/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-assignment */
/* eslint-disable camelcase */
import React, { FC, useEffect, useState } from 'react'
import {
  Flex,
  Button,
  Stack,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Textarea,
  Text,
  Center,
  Spinner,
  CloseButton,
  List,
  ListItem,
  Tag
} from '@chakra-ui/react'
import format from 'date-fns/format'
import { v4 as uuidV4 } from 'uuid'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AiFillLock, AiFillUnlock } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import { LayoutDefault } from '../../layouts/Default'
import { CardContainer } from '../../components/molecules/CardContainer'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { Input } from '../../components/Form/Input'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { apiAuth } from '../../services/apiAuth'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { WaysData } from '../../hooks/waysReceives/useWays'
import { useAuth } from '../../hooks/auth'
import { ModalContentComponent } from '../../components/Modal/modalContent'
import { SelectComponentAsync } from '../../components/Form/SelectComponentAsync'
import { PermissionComponent } from '../../components/Permissions'

type paramsProps = {
  id: string
}

type Attendance = {
  id: number
  guide: number
  patient_id: number
  scheduled_arrival_time: Date
  scheduled_initial_time: string
  scheduled_final_time: string
  real_arrival_time: string | null
  real_initial_time: string | null
  real_final_time: string | null
  status: number
  professional_id: number
  observation: string | null
  observation_payment: string | null
  value_to_income: number | null
  invoice_number: number | null
  invoice_date: string | null
  block_guide: boolean
  is_active: boolean
  specialist_name: string | null
  patient_name: string | null
  patient_social_name: string | null
  patient_birth_date: string | null
  patient_alerts: string | null
  attendance_items_exclude?: Array<number>
  attendance_items: Array<{
    cost_center_id: number | null
    discount_currency: string
    discount_percentage: string
    addition: string
    id: number
    initial_price: number
    service_id: number
    service_name: string
    total_session: number
  }>
}

type Item = {
  event_date: string
  id: number | string
  number_parcels: number
  observation: string | null
  parcel_value: number
  value: number
  date_status_conferred?: string | null
  status_conferred?: number | null
  way_to_receive: {
    id: number
    name: string
  }
}

type ExcludeType = 'guide' | 'way' | 'service'

type KeyOptions =
  | 'initial_price'
  | 'discount_percentage'
  | 'discount_currency'
  | 'addition'
  | 'final_price'

type PaymentOptions = Array<{
  id: string | number
  dateEvent: string
  date_status_conferred?: string | null
  status_conferred?: number | null
  wayToReceive: {
    id: number
    name: string
  }
  value: string
  numberParcels: number
  valueParcels: string
  observation: string | undefined
}>

type SelectStructure = {
  label: string
  value: number
}

type SelectOptionsParcel = Array<SelectStructure>

type SelectWayType = SelectStructure | null

type SelectWayParcel = {
  value: number
  label: number
} | null

type Ticket = {
  attendances: Array<Attendance>
  items: Array<Item>
  status_payment: number | null
  ticket_number: string | null
  result_status: number
  discount: number | null
  addition: number | null
  observation_secretary?: string
}

type Patient = {
  value: number
  label: string
} | null

// debounce
let timer: any = null

export const Checkout: FC = () => {
  // hooks
  const toast = useToast()
  const { permissionFunction, currency } = useAuth()
  const { id } = useParams<paramsProps>()
  const [t] = useTranslation('pageCheckout')
  const isMaster = permissionFunction()
  const isExcludePayment = permissionFunction(
    'DELETE_CUSTOMER_PAYMENT',
    'DELETE'
  )

  // init states
  const [attendances, setAttendances] = useState<Attendance[]>([])
  const [ticket, setTicket] = useState<Ticket | null>(null)
  const [isFinishedLoading, setIsFinishedLoading] = useState(false)
  const [ticketDiscount, setTicketDiscount] = useState('')
  const [ticketAddition, setTicketAddition] = useState('')
  const [ticketObservation, setTicketObservation] = useState('')
  const [loadingTicket, setLoadingTicket] = useState(false)
  const [patientSelected, setPatientSelected] = useState<Patient>(null)

  // wayToReceive states
  const [selectWaysToReceives, setSelectWaysToReceives] = useState<
    WaysData[] | []
  >([])
  const [wayToReceives, setWayToReceives] = useState<PaymentOptions>([])

  // inputs to wayToReceives
  const [eventDate, setEventDate] = useState(format(new Date(), 'yyyy-MM-dd'))
  const [selectedWay, setSelectedWay] = useState<SelectWayType>(null)
  const [value, setValue] = useState('')
  const [numberParcels, setNumberParcels] = useState<SelectWayParcel>(null)
  const [numbersParcelsOptions, setNumberParcelsOptions] =
    useState<SelectOptionsParcel>([])
  const [valueParcels, setValueParcels] = useState('')
  const [observationWay, setObservationWay] = useState('')
  const [typeAction, setTypeAction] = useState<'include' | 'edit'>('include')
  const [idEdit, setIdEdit] = useState<string | number>('')

  // modal add guide states
  const [isOpen, setIsOpen] = useState(false)
  const [attendancesList, setAttendancesList] = useState<Attendance[]>([])

  // modal confirme exclude guide
  const [isOpenExclude, setIsOpenExclude] = useState(false)
  const [indexExclude, setIndexExclude] = useState(1)
  const [typeExclude, setTypeExclude] = useState<ExcludeType>('guide')
  const [indexServiceExclude, setIndexServiceExclude] = useState(0)

  // modal confirme generateTicket
  const [isOpenTicketGenerate, setIsOpenTicketGenerate] = useState(false)

  // modal include wayToReceives in Ticket
  const [isOpenWayToReceives, setIsOpenWayToReceives] = useState(false)

  // function open modal add guid e
  const handlerOpenAddGuideModal = () => {
    setIsOpen(true)
  }

  // function add attendance in array attendances
  const handlerAddGuideToPayArray = async (idAttendance: number) => {
    try {
      const { data } = await apiAuth.get<Attendance>(
        `clinics/attendances/${idAttendance}/`
      )

      const attendance_items = data.attendance_items.map((item) => {
        return {
          cost_center_id: item.cost_center_id,
          id: item.id,
          service_id: item.service_id,
          initial_price: item.initial_price,
          total_session: item.total_session,
          service_name: item.service_name,
          discount_percentage: formatBrOrDollarDefault(
            String(Number(item.discount_percentage) * 100)
          ),
          discount_currency: formatBrOrDollarDefault(
            String(Number(item.discount_currency) * 100)
          ),
          addition: formatBrOrDollarDefault(String(Number(item.addition) * 100))
        }
      })

      const addData = {
        attendance_items
      }

      setAttendances([...attendances, { ...data, ...addData }])
    } catch (error: any) {
      console.log(error)
    }
  }

  // function remove item
  const handlerRemoveItem = (
    index: number,
    type: ExcludeType,
    indexService: number
  ) => {
    if (type === 'guide') {
      const values = [...attendances]
      values.splice(index, 1)

      setAttendances(values)
    } else if (type === 'way') {
      const valuesWay = [...wayToReceives]
      valuesWay.splice(index, 1)
      setWayToReceives(valuesWay)
    } else if (type === 'service') {
      const values = [...attendances]
      const itemExclude = [values[index].attendance_items[indexService].id]
      const backUpItemsExclude = values[index]?.attendance_items_exclude ?? []
      values[index].attendance_items.splice(indexService, 1)
      values[index].attendance_items_exclude = [
        ...backUpItemsExclude,
        ...itemExclude
      ]

      setAttendances(values)
    }
  }

  // get client
  const getClients = async (search?: string) => {
    if (search !== undefined && search?.length <= 2) {
      return []
    }

    try {
      const { data } = await apiAuth.get(`clinics/patients/`, {
        params: {
          is_active: true,
          name__icontains: search ?? '',
          noPaginate: 1
        }
      })

      return data.results.map((client: any) => {
        return {
          value: client.id,
          label: client.name
        }
      })
    } catch (err: any) {
      console.log(err)
      return []
    }
  }

  // get all wayToReceives selected
  useEffect(() => {
    const getWaysToReceives = async () => {
      const { data } = await apiAuth.get('clinics/ways-receives/', {
        params: {
          is_active: true
        }
      })

      setSelectWaysToReceives(data.results)
    }

    getWaysToReceives()

    return () => {}
  }, [])

  useEffect(() => {
    const loadTicketData = async () => {
      const { data } = await apiAuth.get<Ticket>(
        `clinics/attendances/${id}/tickets/`
      )

      if (data?.ticket_number === '') {
        handleEmptyTicket(data)
      } else {
        handleExistingTicket(data)
      }
    }

    const handleEmptyTicket = async (data: Ticket) => {
      if (data?.attendances?.length === 0) {
        const response = await apiAuth.get<Attendance>(
          `clinics/attendances/${id}/`
        )
        const attendance_items = formatAttendanceItems(
          response.data.attendance_items
        )

        const addData = { attendance_items }
        const attendance = [{ ...response.data, ...addData }]

        setAttendances(attendance)
        setIsFinishedLoading(true)
      }
    }

    const handleExistingTicket = (data: Ticket) => {
      setTicket(data)

      const formatAttendance = data?.attendances?.map((attendance) => {
        const attendance_items = formatAttendanceItems(
          attendance.attendance_items
        )
        return { ...attendance, attendance_items }
      })

      const formatItems = data?.items?.map((item) => formatItemsData(item))

      setAttendances(formatAttendance)
      setWayToReceives(formatItems)
      setTicketDiscount(
        formatBrOrDollarDefault(data?.discount?.toFixed(2) ?? '')
      )
      setTicketAddition(
        formatBrOrDollarDefault(data?.addition?.toFixed(2) ?? '')
      )
      setTicketObservation(data?.observation_secretary ?? '')
      setIsFinishedLoading(true)
    }

    const formatAttendanceItems = (items: any[]) => {
      return items.map((item) => ({
        cost_center_id: item.cost_center_id,
        id: item.id,
        service_id: item.service_id,
        initial_price: item.initial_price,
        total_session: item.total_session,
        service_name: item.service_name,
        discount_percentage: formatBrOrDollarDefault(
          String(Number(item.discount_percentage) * 100)
        ),
        discount_currency: formatBrOrDollarDefault(
          String(Number(item.discount_currency) * 100)
        ),
        addition: formatBrOrDollarDefault(String(Number(item.addition) * 100))
      }))
    }

    const formatItemsData = (item: any) => {
      return {
        id: item.id,
        dateEvent: item.event_date.substring(0, 10),
        wayToReceive: {
          id: item.way_to_receive.id,
          name: item.way_to_receive.name
        },
        value: formatBrOrDollarDefault(item.value.toFixed(2)),
        numberParcels: item?.number_parcels,
        valueParcels: formatBrOrDollarDefault(item.parcel_value.toFixed(2)),
        observation: item?.observation ?? undefined,
        status_conferred: item?.status_conferred
      }
    }

    loadTicketData()

    return () => {}
  }, [id])

  useEffect(() => {
    if (patientSelected === null) {
      setAttendancesList([])
      return
    }

    const idsExclude = attendances
      .map((att) => {
        return att.guide
      })
      .join(',')

    const getAttendanceList = async () => {
      const { data } = await apiAuth.get('clinics/attendances/autocomplete/', {
        params: {
          patient_id: patientSelected?.value,
          exclude_guides: idsExclude,
          no_ticket: '1',
          exclude_status: '3,4'
        }
      })

      // formatar os valores de attendances itens
      setAttendancesList(data)
    }

    getAttendanceList()
  }, [attendances, patientSelected])

  // handler Generate Ticket
  const handlerGenerateTicket = async () => {
    setLoadingTicket(true)

    try {
      const { data } = await apiAuth.patch<Ticket>(
        `clinics/attendances/${id}/tickets/`,
        {
          attendances: attendances.map((attendance) => {
            return {
              id: attendance.id
            }
          }),
          discount: Number(remaskCaractersAll(ticketDiscount)) / 100,
          addition: Number(remaskCaractersAll(ticketAddition)) / 100,
          status_payment: 1
        }
      )

      if (data.ticket_number !== undefined) {
        setTicket(data)
      }
    } catch (error: any) {
      toast({
        title: `${t('errors.title')}`,
        description: `${t('errors.errorMessage')} ${
          error?.response?.data?.detail
        }`,
        duration: 3500,
        isClosable: true,
        position: 'top',
        status: 'error'
      })
    }

    setLoadingTicket(false)
  }

  // inputs guides values
  // discountPercent
  const handlerChangeValueDiscountPercent = (
    index: number,
    indexService: number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const values = [...attendances]

    values[index].attendance_items[indexService].discount_percentage =
      event.currentTarget.value

    setAttendances(values)
  }

  const handlerChangeValueObservationPayment = (
    index: number,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const values = [...attendances]

    values[index].observation_payment = event?.currentTarget?.value ?? null

    setAttendances(values)
  }

  // discountCurrency
  const handlerChangeValueDiscountCurrency = (
    index: number,
    indexService: number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const values = [...attendances]

    values[index].attendance_items[indexService].discount_currency =
      event.currentTarget.value

    setAttendances(values)
  }

  // addition
  const handlerChangeValueAddition = (
    index: number,
    indexService: number,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const values = [...attendances]

    values[index].attendance_items[indexService].addition =
      event.currentTarget.value

    setAttendances(values)
  }

  // logic calculations
  // discountPercent
  const discountPercentagemInPrice = (
    initialValue: number,
    discountPercent: string | null
  ) => {
    const discountCurrency =
      Number(remaskCaractersAll(discountPercent ?? '0')) / 100
    const valueMultiple = initialValue * discountCurrency
    const valueFinal = valueMultiple / 100
    const valueFixed = valueFinal.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  const finalPriceServiceLine = (
    initialValue: number,
    discountPercent: string | null,
    discountCurrency: string | null,
    addition: string | null,
    isMask?: boolean
  ) => {
    const valueDiscountPercent =
      (initialValue * Number(remaskCaractersAll(discountPercent ?? '') ?? 0)) /
      10000
    const valueDiscountCurrency =
      Number(remaskCaractersAll(discountCurrency ?? '')) / 100
    const valueAddition = Number(remaskCaractersAll(addition ?? '')) / 100

    const finalPrice =
      initialValue -
      valueDiscountPercent -
      valueDiscountCurrency +
      valueAddition

    if (isMask) {
      return formatBrOrDollarDefault(finalPrice.toFixed(2))
    }

    return finalPrice.toFixed(2)
  }

  // all addition receive key
  const additionReduceTotal = (index: number, key: KeyOptions) => {
    const arraySelect = attendances[index].attendance_items
    const formatArraySelect = arraySelect.map((item) => {
      const percent =
        (item.initial_price *
          Number(remaskCaractersAll(item.discount_percentage ?? '') ?? 0)) /
        10000

      return {
        initial_price: item.initial_price,
        discount_percentage: percent,
        discount_currency:
          Number(remaskCaractersAll(item.discount_currency ?? '')) / 100,
        addition: Number(remaskCaractersAll(item.addition ?? '')) / 100,
        final_price: Number(
          finalPriceServiceLine(
            item.initial_price,
            item.discount_percentage,
            item.discount_currency,
            item.addition
          )
        )
      }
    })

    return formatArraySelect.reduce(
      (accumulator, item) => accumulator + (item[key] ?? 0),
      0
    )
  }

  // return Total value in guides
  const totalValueAllGuides = () => {
    let total: number = 0
    attendances.forEach((attendance, index) => {
      const finalValueGuide = additionReduceTotal(index, 'final_price')
      total = total + finalValueGuide
    })

    const valueFixed = total.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  // return prevision to pay
  const returnPrevisionPaymentGuides = () => {
    const totalValueGuidesNumber =
      Number(remaskCaractersAll(totalValueAllGuides())) / 100
    const discount = Number(remaskCaractersAll(ticketDiscount)) / 100
    const addition = Number(remaskCaractersAll(ticketAddition)) / 100

    const valueToPay = totalValueGuidesNumber - discount + addition

    const valueFixed = valueToPay.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  // inputs and changes wayToReceives
  // select way
  const handlerSelectWayToReceive = (event: any) => {
    const values = [...selectWaysToReceives]
    values.forEach((wayToReceive) => {
      if (wayToReceive.id === event.value) {
        const arrayParcelsGenerator = wayToReceive.way_to_receive_parcels.map(
          (parcel) => {
            if (parcel.is_active === true) {
              return {
                label: String(parcel.parcel_number + 1),
                value: parcel.parcel_number + 1
              }
            }

            return undefined
          }
        )

        const formatArray: any = arrayParcelsGenerator.filter(Boolean)

        if (formatArray.length === 1) {
          setNumberParcels(formatArray[0])
          setValue('')
        } else {
          setNumberParcels(null)
        }

        setNumberParcelsOptions(formatArray)
      }
    })

    setSelectedWay(event)
    setValueParcels('')
  }

  const handlerSelectNumbersOfParcels = (event: any) => {
    setNumberParcels(event)

    if (value) {
      const valueChange = Number(remaskCaractersAll(value)) / 100
      const parcelValueNumber = valueChange / (event?.value ?? 0)

      const parcelStringValue = formatBrOrDollarDefault(
        parcelValueNumber.toFixed(2)
      )

      setValueParcels(parcelStringValue)
    }
  }

  // input for calculate value parcel and update
  const handlerChangeValueStateForWay = (
    event: React.FormEvent<HTMLInputElement>
  ) => {
    const valueChange =
      Number(remaskCaractersAll(event.currentTarget.value)) / 100
    const parcelValueNumber = valueChange / (numberParcels?.value ?? 0)

    const parcelStringValue = formatBrOrDollarDefault(
      parcelValueNumber.toFixed(2)
    )

    setValueParcels(parcelStringValue)
  }

  // handler add way_to_receives in ticket
  const handlerAddWaysToReceives = () => {
    if (!eventDate) {
      toast({
        title: `${t('errors.titleItems')}`,
        description: `${t('errors.errorEventDate')}`,
        duration: 3500,
        isClosable: true,
        position: 'top-right',
        status: 'error'
      })

      return
    }

    if (!selectedWay) {
      toast({
        title: `${t('errors.titleItems')}`,
        description: `${t('errors.errorSelectedWay')}`,
        duration: 3500,
        isClosable: true,
        position: 'top-right',
        status: 'error'
      })

      return
    }

    if (!numberParcels) {
      toast({
        title: `${t('errors.titleItems')}`,
        description: `${t('errors.errorSelectParcel')}`,
        duration: 3500,
        isClosable: true,
        position: 'top-right',
        status: 'error'
      })

      return
    }

    if (!value) {
      toast({
        title: `${t('errors.titleItems')}`,
        description: `${t('errors.errorValue')}`,
        duration: 3500,
        isClosable: true,
        position: 'top-right',
        status: 'error'
      })

      return
    }

    /* logic para o backEnd */

    setWayToReceives([
      ...wayToReceives,
      {
        dateEvent: eventDate,
        id: uuidV4(),
        numberParcels: numberParcels?.value ?? 0,
        observation: observationWay ?? undefined,
        value,
        valueParcels,
        wayToReceive: {
          id: selectedWay?.value ?? 0,
          name: selectedWay?.label ?? ''
        }
      }
    ])

    setIsOpenWayToReceives(false)

    // reset values
    setEventDate(format(new Date(), 'yyyy-MM-dd'))
    setSelectedWay(null)
    setValue('')
    setNumberParcels(null)
    setNumberParcelsOptions([])
    setValueParcels('')
    setObservationWay('')
  }

  // handler edit way_to_receives in ticket
  const handlerEditWaysToReceives = (idWay: string | number) => {
    if (!eventDate) {
      toast({
        title: `${t('errors.titleItems')}`,
        description: `${t('errors.errorEventDate')}`,
        duration: 3500,
        isClosable: true,
        position: 'top-right',
        status: 'error'
      })

      return
    }

    if (!selectedWay) {
      toast({
        title: `${t('errors.titleItems')}`,
        description: `${t('errors.errorSelectedWay')}`,
        duration: 3500,
        isClosable: true,
        position: 'top-right',
        status: 'error'
      })

      return
    }

    if (!numberParcels) {
      toast({
        title: `${t('errors.titleItems')}`,
        description: `${t('errors.errorSelectParcel')}`,
        duration: 3500,
        isClosable: true,
        position: 'top-right',
        status: 'error'
      })

      return
    }

    if (!value) {
      toast({
        title: `${t('errors.titleItems')}`,
        description: `${t('errors.errorValue')}`,
        duration: 3500,
        isClosable: true,
        position: 'top-right',
        status: 'error'
      })

      return
    }

    /* logic para o backEnd */

    const allData = {
      dateEvent: eventDate,
      id: idWay ?? uuidV4(),
      numberParcels: numberParcels?.value ?? 0,
      observation: observationWay ?? undefined,
      value,
      valueParcels,
      wayToReceive: {
        id: selectedWay?.value ?? 0,
        name: selectedWay?.label ?? ''
      }
    }

    const newArrayWays = wayToReceives.map((way) => {
      return idWay === way.id ? { ...way, ...allData } : way
    })

    setWayToReceives(newArrayWays)

    setIsOpenWayToReceives(false)

    // reset values
    setEventDate(format(new Date(), 'yyyy-MM-dd'))
    setSelectedWay(null)
    setValue('')
    setNumberParcels(null)
    setNumberParcelsOptions([])
    setValueParcels('')
    setObservationWay('')
  }

  // calculate way_to_receives for pay and pay partial
  const returnsTheAmountAlreadyPaid = () => {
    let total: number = 0

    wayToReceives.forEach((way) => {
      const valueTotalWay = Number(remaskCaractersAll(way.value)) / 100

      total = total + valueTotalWay
    })

    const valueFixed = total.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  // returns Remains to be paid
  const returnsTheAmountThatRemainsToBePaid = () => {
    let valueRemainsToBePaid: number = 0

    const valuePaid =
      Number(remaskCaractersAll(returnsTheAmountAlreadyPaid())) / 100
    const valueTotalTicket =
      Number(remaskCaractersAll(returnPrevisionPaymentGuides())) / 100

    valueRemainsToBePaid = valueTotalTicket - valuePaid

    const valueFixed = valueRemainsToBePaid.toFixed(2)
    const formatCurrency = formatBrOrDollarDefault(valueFixed)

    return formatCurrency
  }

  // jxs status item ticket
  const returnJsxElementStatusTicket = () => {
    const balance =
      Number(remaskCaractersAll(returnsTheAmountThatRemainsToBePaid())) / 100
    const valuePaid =
      Number(remaskCaractersAll(returnsTheAmountAlreadyPaid())) / 100
    const valueTotalTicket =
      Number(remaskCaractersAll(returnPrevisionPaymentGuides())) / 100

    if (valuePaid === 0) {
      return (
        <Tag
          size="sm"
          bg="#c71717f4"
          color="white"
          fontSize="14px"
          fontWeight="bold"
        >
          {t('statusTicket.1')}
        </Tag>
      )
    }

    if (balance === 0) {
      return (
        <Tag
          size="sm"
          bg="#56c20ff4"
          color="white"
          fontSize="14px"
          fontWeight="bold"
        >
          {t('statusTicket.2')}
        </Tag>
      )
    }

    if (valuePaid > 0 && valuePaid < valueTotalTicket) {
      return (
        <Tag
          size="sm"
          bg="#fd7011f0"
          color="white"
          fontSize="14px"
          fontWeight="bold"
        >
          {t('statusTicket.3')}
        </Tag>
      )
    }

    if (valuePaid > valueTotalTicket) {
      return (
        <Tag
          size="sm"
          bg="#192facdc"
          color="white"
          fontSize="14px"
          fontWeight="bold"
        >
          {t('statusTicket.4')}
        </Tag>
      )
    }

    return null
  }

  // logic status item ticket boolean value
  const returnNotCanAddByElementStatusTicket = () => {
    const balance =
      Number(remaskCaractersAll(returnsTheAmountThatRemainsToBePaid())) / 100
    const valuePaid =
      Number(remaskCaractersAll(returnsTheAmountAlreadyPaid())) / 100
    const valueTotalTicket =
      Number(remaskCaractersAll(returnPrevisionPaymentGuides())) / 100

    if (valuePaid === 0) {
      return false
    }

    if (balance === 0) {
      return true
    }

    if (valuePaid > 0 && valuePaid < valueTotalTicket) {
      return false
    }

    if (valuePaid > valueTotalTicket) {
      return true
    }

    return false
  }

  const returnNumberStatusTicket = () => {
    const balance =
      Number(remaskCaractersAll(returnsTheAmountThatRemainsToBePaid())) / 100
    const valuePaid =
      Number(remaskCaractersAll(returnsTheAmountAlreadyPaid())) / 100
    const valueTotalTicket =
      Number(remaskCaractersAll(returnPrevisionPaymentGuides())) / 100

    if (valuePaid === 0) {
      return 1
    }

    if (balance === 0) {
      return 2
    }

    if (valuePaid > 0 && valuePaid < valueTotalTicket) {
      return 3
    }

    if (valuePaid > valueTotalTicket) {
      return 4
    }

    return null
  }

  // rule for generateTicket
  const returnBooleanRuleBlockGeneratorTicket = () => {
    const totalGuides = totalValueAllGuides()

    if (totalGuides === '') {
      return true
    }

    return false
  }
  useEffect(() => {
    clearTimeout(timer)

    timer = setTimeout(() => {
      const dataTotal = formatAttendanceData(attendances)

      saveAttendancesData(dataTotal)
      updateTicketDataIfNeeded(ticket, attendances)
    }, 700)

    return () => {}
  }, [attendances])

  const formatAttendanceData = (attendances: any[]) => {
    return attendances.map((attendance) => {
      const attendance_items = attendance.attendance_items.map((item: any) => ({
        cost_center_id: item.cost_center_id,
        discount_percentage:
          Number(remaskCaractersAll(item.discount_percentage)) / 100,
        discount_currency:
          Number(remaskCaractersAll(item.discount_currency)) / 100,
        addition: Number(remaskCaractersAll(item.addition)) / 100,
        total_session: item.total_session,
        service_name: item.service_name,
        initial_price: item.initial_price,
        service_id: item.service_id,
        id: item.id
      }))

      const attendance_items_exclude =
        attendance?.attendance_items_exclude ?? []

      return { ...attendance, attendance_items, attendance_items_exclude }
    })
  }

  const saveAttendancesData = (dataTotal: any[]) => {
    dataTotal.forEach((payload) => {
      apiAuth.put(`clinics/attendances/${payload.id}/`, payload)
    })
  }

  const updateTicketDataIfNeeded = (ticket: any, attendances: any[]) => {
    if (ticket) {
      const allAttendancesIds = attendances.map((attendance) => ({
        id: attendance.id
      }))
      apiAuth.patch(`clinics/attendances/${id}/tickets/`, {
        attendances: allAttendancesIds,
        status_payment: returnNumberStatusTicket()
      })
    }
  }

  // save discount and addition in ticket
  useEffect(() => {
    clearTimeout(timer)

    timer = setTimeout(() => {
      if (ticket) {
        apiAuth.patch(`clinics/attendances/${id}/tickets/`, {
          attendances: attendances.map((attendance) => {
            return {
              id: attendance.id
            }
          }),
          discount: Number(remaskCaractersAll(ticketDiscount)) / 100,
          addition: Number(remaskCaractersAll(ticketAddition)) / 100,
          observation_secretary: ticketObservation,
          status_payment: returnNumberStatusTicket()
        })
      }
    }, 700)

    return () => {}
  }, [ticketDiscount, ticketAddition, ticketObservation])

  // save infos items (paid)
  useEffect(() => {
    const formatWayToReceiveItems = () => {
      const formatWays = wayToReceives.map((way) => {
        return {
          way_to_receive: {
            id: way.wayToReceive.id
          },
          id: String(way.id).length < 36 ? way.id : undefined,
          value: Number(remaskCaractersAll(way.value)) / 100,
          number_parcels: way.numberParcels,
          event_date: `${way.dateEvent}T00:00:00`,
          observation: way.observation,
          status_conferred: way?.status_conferred ?? undefined,
          date_status_conferred: way?.date_status_conferred ?? undefined
        }
      })

      return formatWays
    }

    const items = formatWayToReceiveItems()

    const payload = {
      items,
      status_payment: returnNumberStatusTicket(),
      attendances: attendances.map((attendance) => {
        return {
          id: attendance.id
        }
      })
    }

    clearTimeout(timer)

    setTimeout(() => {
      if (ticket) {
        apiAuth.patch(`clinics/attendances/${id}/tickets/`, payload)
      }
    }, 700)
  }, [wayToReceives])

  if (!isFinishedLoading) {
    return (
      <LayoutDefault urlBack="/scheduling" title={`${t('title')}`}>
        <Center h="100vh" w="100vw">
          <Spinner color="blue.300" size="lg" />
        </Center>
      </LayoutDefault>
    )
  }

  return (
    <>
      <LayoutDefault urlBack="/scheduling" title={`${t('title')}`}>
        <Stack justify="space-evenly" spacing="2" w="100%">
          {attendances?.map((attendance, index) => (
            <CardContainer
              title={`${t('guide')}#${attendance?.guide} - ${
                attendance.patient_name
              } - ${format(
                new Date(attendance.scheduled_arrival_time),
                'dd/MM/yyyy'
              )} - ${t('specialist')}${attendance?.specialist_name}`}
              key={attendance.id}
            >
              <Box position="absolute" top="4" right="14">
                {attendance.block_guide ? (
                  <AiFillLock size={28} color="red" />
                ) : (
                  <AiFillUnlock size={28} color="green" />
                )}
              </Box>
              {!ticket && attendance.id !== Number(id) && (
                <CloseButton
                  position="absolute"
                  top="4"
                  right="4"
                  bg="transparent"
                  color="black"
                  onClick={() => {
                    setIndexExclude(index)
                    setTypeExclude('guide')
                    setIsOpenExclude(true)
                  }}
                  _hover={{
                    cursor: 'pointer',
                    bg: 'red',
                    color: 'white'
                  }}
                />
              )}
              {ticket && isMaster && attendance.id !== Number(id) && (
                <CloseButton
                  position="absolute"
                  top="4"
                  right="4"
                  bg="transparent"
                  color="black"
                  onClick={() => {
                    setIndexExclude(index)
                    setTypeExclude('guide')
                    setIsOpenExclude(true)
                  }}
                  _hover={{
                    cursor: 'pointer',
                    bg: 'red',
                    color: 'white'
                  }}
                />
              )}
              <Flex my="2" gap={2} flexDir="column">
                <PermissionComponent ipCode="CREATE" spiCode="NOTES">
                  <Link
                    to={{
                      pathname: '/notes/create',
                      state: {
                        patient: {
                          value: attendance.patient_id,
                          label: attendance.patient_name
                        },
                        back: `/financial/checkout/${attendance.id}`
                      }
                    }}
                  >
                    <Button
                      size="xs"
                      bg="blue.300"
                      mx="2"
                      mb="2px"
                      color="white"
                    >
                      Novo lembrete para {attendance?.patient_name}
                    </Button>
                  </Link>
                </PermissionComponent>
                <Text fontSize="14px">{t('observationPayment')}</Text>
                <Textarea
                  placeholder={t('placeholderObservationPayment')}
                  name="paymentObservation"
                  disabled={attendance.block_guide}
                  value={attendance?.observation_payment ?? ''}
                  onChange={(event) =>
                    handlerChangeValueObservationPayment(index, event)
                  }
                />
              </Flex>
              <Table>
                <Thead>
                  <Tr>
                    <Th w="200px">{t('serviceName')}</Th>
                    <Th textAlign="right">{t('initialValue')}</Th>
                    <Th textAlign="right">{t('percentDiscount')}</Th>
                    <Th textAlign="right">{t('valueDiscount')}</Th>
                    <Th textAlign="right">{t('discount')}</Th>
                    <Th textAlign="right">{t('addition')}</Th>
                    <Th textAlign="right">{t('finalValue')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {attendance?.attendance_items?.map((item, indexService) => (
                    <Tr key={item?.id}>
                      <Td>
                        <Text fontSize="xs" fontWeight="bold">
                          {item.service_name}
                        </Text>
                      </Td>
                      <Td>
                        <Box>
                          <TextFieldControlled
                            name="flashRate"
                            mask={
                              currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                            }
                            maxLength={10}
                            disabled
                            value={formatBrOrDollarDefault(
                              String(item.initial_price.toFixed(2))
                            )}
                          />
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <TextFieldControlled
                            max={100}
                            min={0.01}
                            disabled={attendance.block_guide}
                            name="percentRate"
                            mask="porcentagem"
                            initialValue={formatBrOrDollarDefault(
                              String(
                                attendances[index].attendance_items[
                                  indexService
                                ].discount_percentage
                              )
                            )}
                            inputOnChange={(event) => {
                              handlerChangeValueDiscountPercent(
                                index,
                                indexService,
                                event
                              )
                            }}
                          />
                        </Box>
                      </Td>
                      <Td textAlign="right">
                        <Box textAlign="right">
                          <Text textAlign="right" fontWeight="hairline">
                            {discountPercentagemInPrice(
                              item.initial_price,
                              item.discount_percentage
                            )}
                          </Text>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <TextFieldControlled
                            name="discount"
                            mask={
                              currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                            }
                            disabled={attendance.block_guide}
                            initialValue={formatBrOrDollarDefault(
                              String(
                                attendances[index].attendance_items[
                                  indexService
                                ].discount_currency
                              )
                            )}
                            inputOnChange={(event) => {
                              handlerChangeValueDiscountCurrency(
                                index,
                                indexService,
                                event
                              )
                            }}
                          />
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <TextFieldControlled
                            name="addition"
                            mask={
                              currency === 'BRL' ? 'monetary' : 'monetaryDollar'
                            }
                            disabled={attendance.block_guide}
                            initialValue={formatBrOrDollarDefault(
                              String(
                                attendances[index].attendance_items[
                                  indexService
                                ].addition
                              )
                            )}
                            inputOnChange={(event) => {
                              handlerChangeValueAddition(
                                index,
                                indexService,
                                event
                              )
                            }}
                          />
                        </Box>
                      </Td>
                      <Td w="200px" textAlign="right">
                        <Stack
                          direction="row"
                          spacing="6"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Text fontWeight="bold" textAlign="right">
                            {finalPriceServiceLine(
                              item.initial_price,
                              item.discount_percentage,
                              item.discount_currency,
                              item.addition,
                              true
                            )}
                          </Text>
                          {!ticket && attendance?.attendance_items?.length > 1 && (
                            <CloseButton
                              bg="transparent"
                              color="black"
                              onClick={() => {
                                setIndexExclude(index)
                                setTypeExclude('service')
                                setIndexServiceExclude(indexService)
                                setIsOpenExclude(true)
                              }}
                              _hover={{
                                cursor: 'pointer',
                                bg: 'red',
                                color: 'white'
                              }}
                            />
                          )}
                          {ticket &&
                            attendance?.attendance_items?.length > 1 &&
                            isMaster && (
                              <CloseButton
                                bg="transparent"
                                color="black"
                                onClick={() => {
                                  setIndexExclude(index)
                                  setTypeExclude('service')
                                  setIndexServiceExclude(indexService)
                                  setIsOpenExclude(true)
                                }}
                                _hover={{
                                  cursor: 'pointer',
                                  bg: 'red',
                                  color: 'white'
                                }}
                              />
                            )}
                        </Stack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Table>
                <Tr>
                  <Th textAlign="left" w="220px">
                    {t('totalGuide')}
                  </Th>
                  <Th textAlign="right" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'initial_price').toFixed(2)
                    )}
                  </Th>
                  <Th>
                    <Box w="180px" />
                  </Th>
                  <Th textAlign="left" w="160px" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'discount_percentage').toFixed(
                        2
                      )
                    )}
                  </Th>
                  <Th textAlign="left" w="162px" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'discount_currency').toFixed(2)
                    )}
                  </Th>
                  <Th textAlign="left" w="162px" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'addition').toFixed(2)
                    )}
                  </Th>
                  <Th textAlign="right" w="145px" fontSize="16px">
                    {formatBrOrDollarDefault(
                      additionReduceTotal(index, 'final_price').toFixed(2)
                    )}
                  </Th>
                </Tr>
              </Table>
            </CardContainer>
          ))}
          <Flex w="100%" justifyContent="flex-start">
            <Button
              size="sm"
              bg="blue.300"
              color="#fff"
              onClick={handlerOpenAddGuideModal}
              disabled={ticket?.result_status === 2 && !isMaster}
            >
              {t('addGuide')}
            </Button>
          </Flex>
          {!ticket ? (
            <CardContainer title={t('fakeTicket')}>
              <Button
                position="absolute"
                top="4"
                size="sm"
                bg="blue.300"
                color="white"
                left="220px"
                isLoading={loadingTicket}
                disabled={returnBooleanRuleBlockGeneratorTicket()}
                onClick={() => setIsOpenTicketGenerate(true)}
              >
                {t('paymentGuide')}
              </Button>
              <Table>
                <Thead>
                  <Tr>
                    <Th textAlign="right">{t('totalGuides')}</Th>
                    {/* <Th textAlign="right">{t('discountTicket')}</Th>
                    <Th textAlign="right">{t('additionTicket')}</Th> */}
                    <Th textAlign="right">{t('payable')}</Th>
                    <Th textAlign="right">{t('paid')}</Th>
                    <Th textAlign="right">{t('balanceToPay')}</Th>
                    <Th>{t('status')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Td textAlign="right">
                    <Text textAlign="right" fontWeight="bold">
                      {totalValueAllGuides()}
                    </Text>
                  </Td>
                  {/* <Td />
                  <Td /> */}
                  <Td />
                  <Td />
                  <Td />
                  <Td />
                </Tbody>
              </Table>
            </CardContainer>
          ) : (
            <CardContainer title={t('ticket')}>
              <Text position="absolute" top="4" left="100px">
                {`#${ticket.ticket_number}`}
              </Text>
              <Table>
                <Thead>
                  <Tr>
                    <Th w="200px" textAlign="right">
                      {t('totalGuides')}
                    </Th>
                    {/* <Th w="180px" textAlign="right">
                      {t('discountTicket')}
                    </Th>
                    <Th w="180px" textAlign="right">
                      {t('additionTicket')}
                    </Th> */}
                    <Th textAlign="right">{t('payable')}</Th>
                    <Th textAlign="right">{t('paid')}</Th>
                    <Th textAlign="right">{t('balanceToPay')}</Th>
                    <Th>{t('status')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Td textAlign="right">
                    <Text textAlign="right" fontWeight="bold">
                      {totalValueAllGuides()}
                    </Text>
                  </Td>
                  {/* <Td>
                    <TextFieldControlled
                      name="discountTicket"
                      mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                      inputOnChange={(event) =>
                        setTicketDiscount(event.currentTarget.value)
                      }
                      disabled={returnNotCanAddByElementStatusTicket()}
                      initialValue={ticketDiscount}
                    />
                  </Td>
                  <Td>
                    <TextFieldControlled
                      name="additionTicket"
                      mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
                      inputOnChange={(event) =>
                        setTicketAddition(event.currentTarget.value)
                      }
                      disabled={returnNotCanAddByElementStatusTicket()}
                      initialValue={ticketAddition}
                    />
                  </Td> */}
                  <Td textAlign="right">{returnPrevisionPaymentGuides()}</Td>
                  <Td textAlign="right">{returnsTheAmountAlreadyPaid()}</Td>
                  <Td textAlign="right">
                    {returnsTheAmountThatRemainsToBePaid()}
                  </Td>
                  <Td>{returnJsxElementStatusTicket()}</Td>
                </Tbody>
              </Table>
              <Flex my="2" gap={2} flexDir="column">
                <Text fontSize="14px">{t('observationTicket')}</Text>
                <Textarea
                  placeholder={t('placeholderObservationTicket')}
                  name="paymentObservation"
                  value={ticketObservation}
                  disabled={ticket?.result_status === 2 && !isMaster}
                  onChange={(event) => setTicketObservation(event.target.value)}
                />
              </Flex>
            </CardContainer>
          )}
          {ticket && (
            <CardContainer title={t('items.title')}>
              <Table>
                <Thead>
                  <Tr>
                    <Th textAlign="left">{t('items.dateEvent')}</Th>
                    <Th textAlign="left">{t('items.wayToPayment')}</Th>
                    <Th textAlign="right">{t('items.value')}</Th>
                    <Th textAlign="center">{t('items.numberParcels')}</Th>
                    <Th textAlign="right">{t('items.amountParcel')}</Th>
                    <Th textAlign="left">{t('items.actions')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {wayToReceives?.map((way, index) => (
                    <Tr key={way.id}>
                      <Td>
                        {format(
                          new Date(
                            Number(way.dateEvent.substring(0, 4)),
                            Number(way.dateEvent.substring(5, 7)) - 1,
                            Number(way.dateEvent.substring(8, 10))
                          ),
                          'dd/MM/yyyy'
                        )}
                      </Td>
                      <Td>{way.wayToReceive.name}</Td>
                      <Td textAlign="right">{way.value}</Td>
                      <Td textAlign="center">{way.numberParcels}</Td>
                      <Td textAlign="right">{way.valueParcels}</Td>
                      <Td>
                        <Stack direction="row" spacing="4">
                          {way?.status_conferred === null && (
                            <Button
                              borderRadius={999}
                              bg="blue.300"
                              color="white"
                              onClick={() => {
                                const event = {
                                  value: way.wayToReceive.id,
                                  label: way.wayToReceive.name
                                }

                                setEventDate(way.dateEvent)
                                handlerSelectWayToReceive(event)
                                setValue(way.value)
                                setValueParcels(way.valueParcels)
                                setObservationWay(way?.observation ?? '')
                                setNumberParcels({
                                  label: way.numberParcels,
                                  value: way.numberParcels
                                })
                                setTypeAction('edit')
                                setIdEdit(way.id)

                                setIsOpenWayToReceives(true)
                              }}
                            >
                              <FiEdit />
                            </Button>
                          )}
                          {isMaster && way?.status_conferred !== null && (
                            <CloseButton
                              bg="transparent"
                              color="black"
                              onClick={() => {
                                setIndexExclude(index)
                                setTypeExclude('way')
                                setIsOpenExclude(true)
                              }}
                              _hover={{
                                cursor: 'pointer',
                                bg: 'red',
                                color: 'white'
                              }}
                            />
                          )}
                          {!isMaster &&
                            way?.status_conferred !== null &&
                            isExcludePayment && (
                              <CloseButton
                                bg="transparent"
                                color="black"
                                onClick={() => {
                                  setIndexExclude(index)
                                  setTypeExclude('way')
                                  setIsOpenExclude(true)
                                }}
                                _hover={{
                                  cursor: 'pointer',
                                  bg: 'red',
                                  color: 'white'
                                }}
                              />
                            )}
                          {!isMaster && way?.status_conferred === null && (
                            <CloseButton
                              bg="transparent"
                              color="black"
                              onClick={() => {
                                setIndexExclude(index)
                                setTypeExclude('way')
                                setIsOpenExclude(true)
                              }}
                              _hover={{
                                cursor: 'pointer',
                                bg: 'red',
                                color: 'white'
                              }}
                            />
                          )}
                        </Stack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Flex w="100%" justifyContent="flex-start" my="2">
                <Button
                  size="sm"
                  bg="blue.300"
                  color="white"
                  disabled={returnNotCanAddByElementStatusTicket()}
                  onClick={() => {
                    setTypeAction('include')
                    setIsOpenWayToReceives(true)
                  }}
                >
                  {t('items.addWayToPayment')}
                </Button>
              </Flex>
            </CardContainer>
          )}
          <Stack w="100%" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalArrivedTime
        title={t('modalAddGuide.title')}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onUpdate={() => {}}
        notRenderFooter
      >
        <Flex flexDir="column" w="100%" h="420px" overscroll="auto" p="2">
          <Box w="100%">
            <SelectComponentAsync
              title={t('modalAddGuide.client')}
              isRequired
              placeholder={t('modalAddGuide.placeholder')}
              loadOptions={(values: string) => getClients(values)}
              isClearable
              value={patientSelected}
              onChange={(e: any) => {
                setPatientSelected(e)
              }}
              name="client"
            />
          </Box>
          <List w="100%" spacing="2" my="2">
            <Flex w="100%" justifyContent="space-between">
              <Text fontWeight="bold">Nº da Guia/Data</Text>
              <Text fontWeight="bold">Nome do cliente</Text>
            </Flex>
            {attendancesList?.map((attendance) => (
              <ListItem
                w="100%"
                p="4"
                border="1px solid #d4d4d4"
                _hover={{
                  cursor: 'pointer'
                }}
                borderRadius={8}
                key={attendance.id}
                onClick={() => {
                  handlerAddGuideToPayArray(attendance.id)
                  setIsOpen(false)
                }}
              >
                <Flex w="100%" justifyContent="space-between">
                  <Stack direction="row" spacing="6">
                    <Text fontWeight="hairline">{attendance?.guide}</Text>
                    <Text fontWeight="hairline">
                      {format(
                        new Date(attendance.scheduled_arrival_time),
                        'dd/MM/yyyy'
                      )}
                    </Text>
                  </Stack>
                  <Box>
                    <Text fontWeight="hairline">
                      {attendance?.patient_name}
                    </Text>
                  </Box>
                </Flex>
              </ListItem>
            ))}
          </List>
          <Flex w="100%" my="6" />
        </Flex>
      </ModalArrivedTime>
      <ModalConfirmExclude
        isOpen={isOpenExclude}
        onConfirmExclude={() => {
          handlerRemoveItem(indexExclude, typeExclude, indexServiceExclude)
          setIsOpenExclude(false)
        }}
        onClose={() => setIsOpenExclude(false)}
        title={t('excludeGuideTitle')}
      >
        {t('excludeGuideDescription')}
      </ModalConfirmExclude>
      <ModalArrivedTime
        isOpen={isOpenTicketGenerate}
        onUpdate={() => {
          handlerGenerateTicket()
          setIsOpenTicketGenerate(false)
        }}
        onClose={() => setIsOpenTicketGenerate(false)}
        title={t('generateTicketTitle')}
      >
        {t('generateTicketDescription')}
      </ModalArrivedTime>
      <ModalContentComponent
        isOpen={isOpenWayToReceives}
        isFull
        onUpdate={() => {
          if (typeAction === 'edit') {
            handlerEditWaysToReceives(idEdit)
          } else {
            handlerAddWaysToReceives()
          }
        }}
        onClose={() => {
          setIsOpenWayToReceives(false)
          // reset values
          setEventDate(format(new Date(), 'yyyy-MM-dd'))
          setSelectedWay(null)
          setValue('')
          setNumberParcels(null)
          setNumberParcelsOptions([])
          setValueParcels('')
          setObservationWay('')
        }}
        notRenderFooter={false}
        title={`${t('modalWay.title')}${returnsTheAmountThatRemainsToBePaid()}`}
      >
        <Stack spacing="4" w="100%" direction="column" h="400px">
          <Stack spacing="2" w="100%" direction="row">
            <Box w="160px">
              <Input
                type="date"
                isRequired
                label={t('items.dateEvent')}
                name="dateEvent"
                value={eventDate}
                onChange={(event) => setEventDate(event.target.value)}
              />
            </Box>
            <SelectComponent
              name="wayToReceives"
              title={t('items.wayToPayment')}
              isRequired
              options={selectWaysToReceives?.map((way) => {
                return {
                  label: way.name,
                  value: way.id
                }
              })}
              placeholder={t('items.placeholder.way')}
              value={selectedWay}
              onChange={(event: any) => handlerSelectWayToReceive(event)}
            />
          </Stack>
          <Stack spacing="2" w="100%" direction="row">
            <TextFieldControlled
              name="value"
              mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
              isRequired
              label={t('items.value')}
              initialValue={value}
              inputOnChange={(event) => {
                setValue(formatBrOrDollarDefault(event.currentTarget.value))
                handlerChangeValueStateForWay(event)
              }}
            />
            <SelectComponent
              name="wayToReceivesParcels"
              title={t('items.numberParcels')}
              isRequired
              placeholder={t('items.placeholder.parcel')}
              options={numbersParcelsOptions}
              value={numberParcels}
              onChange={(event: any) => handlerSelectNumbersOfParcels(event)}
            />
            <TextFieldControlled
              name="amountParcel"
              mask={currency === 'BRL' ? 'monetary' : 'monetaryDollar'}
              label={t('items.amountParcel')}
              initialValue={valueParcels}
              disabled
            />
          </Stack>
          <Flex flexDir="column">
            <Text fontSize="14px" mb="2">
              {t('items.observation')}
            </Text>
            <Textarea
              placeholder={t('items.placeholder.observation')}
              value={observationWay}
              onChange={(event) => setObservationWay(event.target.value)}
            />
          </Flex>
        </Stack>
      </ModalContentComponent>
    </>
  )
}
