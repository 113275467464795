/* eslint-disable camelcase */
import { useQuery } from 'react-query'
import { apiAuth } from '../../services/apiAuth'

export type NotesData = {
  id: number
  name: string
  message: string
  type: number
  created_by: string
  is_cheked: boolean
  created_at: string
  update_by: string
  created_by_name: string
  event_date_format: string
  updated_by_name: string
  updated_at: string
  event_date: string
  specialists:
    | {
        id: number
        name: string
        social_name: string
      }[]
    | []
  equipments:
    | {
        id: number
        name: string
      }[]
    | []
  users:
    | {
        id: number
        name: string
        social_name: string
      }[]
    | []
  patients:
    | {
        id: number
        name: string
        social_name: string
      }[]
    | []

  is_active: boolean
}

export type ResponseGetNotes = {
  notes: NotesData[]
  totalCountOfPage: number
}

export const getNotes = async (
  page: number,
  search: string = '',
  isActive: boolean = true,
  patients: any = [],
  types: any = [],
  specialists: any = [],
  equipments: any = [],
  users: any = []
): Promise<ResponseGetNotes> => {
  try {
    const { data } = await apiAuth.get(`clinics/notes/`, {
      params: {
        page,
        keyword: search,
        is_active: isActive,
        patients: patients.join(','),
        type: types.join(','),
        specialists: specialists.join(','),
        equipments: equipments.join(','),
        users: users.join(',')
      }
    })

    const totalCountOfPage = data.count
    const notes: NotesData[] = data.results

    return {
      notes,
      totalCountOfPage
    }
  } catch (error: any) {
    console.log(error)

    const notes: NotesData[] = []
    const totalCountOfPage = 0
    return {
      notes,
      totalCountOfPage
    }
  }
}

export function useNotes(
  page: number,
  functionLoading: Promise<ResponseGetNotes>,
  search: string,
  isActive: boolean,
  options?: {}
) {
  return useQuery(['notes', page, search, isActive], () => functionLoading)
}
